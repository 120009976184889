<template>
  <div
    class="w-full scrollbar-hide p-2 scrollbar-hide bg-gray-100 dark:bg-bggraym dark:text-gray-50 min-h-screen md:p-6 lg:p-16 screen-media"
  >
    <div
      class="w-full bg-mywhite dark:bg-bggrayl dark:text-gray-50 min-h-screen flex flex-row rounded-3xl lg:p-3 screen-media"
    >
      <table class="table-fixed w-100% text-left scrollbar-hide">
        <thead>
          <tr>
            <th>Levels</th>
            <th>Questions</th>
          </tr>
        </thead>
        <tbody class="scrollbar-hide">
          <tr class="scrollbar-hide">
            <td
              class="align-top scrollbar-hide level border-r border-gray-200 max-h-screen overflow-scroll"
              style="height: 800px"
            >
              <div class="flex-col w-72 max-h-screen scrollbar-hide overflow-scroll p-2">
                <div class="flex flex-col">
                  <div class="flex flex-row">
                    <button
                      @click="openDialogAddLevel"
                      class="mt-5 mb-5 btn-add"
                    >
                      + Add level
                    </button>
                    <button
                      @click="openDialogFilter"
                      class="mt-5 mb-5 ml-auto btn-add float-right"
                    >
                      <i class="fas fa-filter"></i>
                    </button>
                  </div>
                  <input
                    v-model="searchQuery"
                    placeholder="Search"
                    class="mt-2 mb-2 pl-2 border rounded-lg float-right"
                  />
                </div>
                <draggable
                  class="dragArea scrollbar-hide list-group w-full"
                  :list="filterContent"
                  @dragend="updateLev"
                >
                  <div
                    class="list-group-item scrollbar-hide flex flex-row p-3 font-bold border border-gray-200 rounded-md mb-1"
                    v-for="level in filterContent"
                    :key="level.id"
                    :class="{ 'active-li': levelActive === level.id }"
                    @click="levelHandleClick(level)"
                  >
                    <div class="flex flex-row pt-3 pr-3"></div>
                    <div class="flex-col justify-center w-full">
                      <p v-if="level.archive" class="text-yellow-500">
                        {{ level.title }}
                      </p>
                      <p v-else>{{ level.title }}</p>
                      <p
                        v-if="level.category"
                        class="font-normal text-mycolorgray"
                      >
                        {{
                          level.category.charAt(0).toUpperCase() +
                          level.category.slice(1)
                        }}
                      </p>
                    </div>

                    <div class="flex flex-row">
                      <div
                        v-if="levelActive === level.id"
                        class="icon ml-3 max-h-8"
                      ></div>
                    </div>
                  </div>
                </draggable>
              </div>
            </td>
            <td class="scrollbar-hide align-top">
              <div class="flex-col w-72 p-2 border-r min-h-screen">
                <div
                  class="flex w-60 h-9 my-72 mx-auto"
                  v-if="!levelActive && !showDialogQuestions"
                >
                  <p class="text-lg font-sans text-gray-400 text-center">
                    Please select level to see Questions
                  </p>
                </div>
                <button
                  v-on:click="openDialogAddQuestions()"
                  v-if="levelActive"
                  class="mt-5 mb-5 btn-add"
                >
                  + Add question
                </button>
                <draggable
                  class="dragArea list-group w-full"
                  :list="questions"
                  @dragend="updateQuestions"
                >
                  <div
                    class="list-group-item scrollbar-hide flex flex-row bg-black-500 p-2 border border-gray-200 rounded-md mb-1"
                    v-for="q in questions"
                    :key="q.id"
                    @click="openDialogUpdateQuestions(q)"
                    :class="{ 'active-li': questionActive === q.id }"
                  >
                    <div></div>
                    <div class="flex-col justify-center w-full">
                      {{ q.question }}
                    </div>
                    <div class="flex flex-row"></div>
                  </div>
                </draggable>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="flex w-60 h-9 mt-80 mx-auto"
        v-if="!questionActive && !levelActive && !showDialogLevel"
      >
        <p class="text-lg font-sans text-gray-400 text-center">
          Please select level to see details
        </p>
      </div>
      <level-form
        v-show="showDialogLevel === true"
        :title="titleModal"
        :level="levelForm"
        :unitList="unitList"
        :type="type"
        @close="closeContent"
        @create="createLevel"
        @update="updateLevel"
        @delete="deleteLevel"
        @deleteAftMoov="deleteAftMoov"
        @move="moveLevel"
      ></level-form>
      <add-questionlevel
        v-show="showDialogQuestions === true"
        :trackField="false"
        :quest="questForm"
        :levelId="levelActive"
        :questionsArr="questions"
        :type="type"
        :questionsType="questionsType"
        @create="createQuest"
        @update="updateQuest"
        @delete="deleteQuest"
        @close="closeContent"
        @cloneLevel="cloneLevel"
        @changeA="changeLevel"
        :key="componentKey"
      ></add-questionlevel>
    </div>
  </div>
  <my-dialog v-model:show="showDialogUnit" :title="titleModal">
    <unit-form
      :unit="unitForm"
      :type="type"
      @close="closeContent"
      @create="createUnit"
      @update="updateUnit"
      @delete="deleteUnit"
    ></unit-form>
  </my-dialog>
  <my-dialog v-model:show="showCheckbox" :title="'Filter'">
    <multi-check-box
      v-model:value="filterValue"
      :options="options"
      @clear="clearData"
    >
    </multi-check-box>
  </my-dialog>
</template>

<script>
import getCollectionOrder from "@/composables/getCollectionUnitsOrder";
import { ref, computed, onMounted } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import AddQuestionlevel from "./modals/questionLvl.vue";
import UnitForm from "@/components/UnitForm";
import LevelForm from "@/components/LevelForm";
import MyDialog from "@/components/MyDialog";
import MultiCheckbox from "./modals/multi-checkbox.vue";
import { DateTime } from "luxon";
import { useRouter } from "vue-router";
import getCollectionUnit from "@/composables/getCollectionUnitsOrder";
import firebase from "firebase/compat";
import { db } from "@/firebase/config";

export default {
  components: {
    UnitForm,
    MyDialog,
    "multi-check-box": MultiCheckbox,
    LevelForm,
    draggable: VueDraggableNext,
    AddQuestionlevel,
  },
  data() {
    return {
      addQuestionType: true,
      addQuestionLevel: true,
      questionsType: null,
    };
  },
  setup() {
    const { documents: units } = getCollectionOrder("Units");
    const { documents: contents } = getCollectionUnit("LevelContent");
    const { documents: questionsContent } = getCollectionOrder("Question");
    const unitActive = ref(null); //id unit
    const levelActive = ref(null); //id level
    const componentKey = ref(0);
    const levelContentActive = ref(null); //id ContentLevel
    const questionActive = ref(null);
    const unitList = ref([]);
    const levelsList = ref([]);
    const questions = ref([]);
    let orderNumber = ref(null);
    let readyToDelete = ref(false);
    let showCheckbox = ref(false);
    let rendered = ref(false);
    let renderedLevels = ref([]);
    let renderedQuestions = ref([]);
    const filterValue = ref([]);
    const options = ref([]);
    let renderedOpenQuestion = {};
    const router = useRouter();
    const unitForm = ref({ title: "", isLive: false });
    const levelForm = ref({
      title: null,
      createdAt: DateTime.now().toMillis(),
      isLive: false,
      category: null,
      questionIds: [],
    });
    const questForm = ref({
      docId: "",
      id: "",
      createdAt: "",
      mediaItemId: null,
      levelId: "",
      unitId: "",
      levelQuestions: [],
    });
    const showDialogUnit = ref(false);
    const showDialogQuestions = ref(false);
    const showDialogLevel = ref(false);
    let searchQuery = ref("");
    const titleModal = ref("modal");
    const type = ref("none");
    const getMyValue = () => {
      filterValue.value = [];
    };

    const getOptions = () => {
      options.value = [
        { name: "ingredients", id: 1 },
        { name: "shopping", id: 2 },
        { name: "prepping", id: 3 },
        { name: "techniques", id: 4 },
        { name: "making", id: 5 },
        { name: "equipment", id: 5 },
      ];
    };
    const clearData = () => {
      filterValue.value = [];
    };

    const forceRerender = () => {
      componentKey.value += 1;
    };

    const filterContent = computed(() => {
      if (contents.value && searchQuery.value) {
        return contents.value.filter((product) => {
          return (
            product.title
              .toLowerCase()
              .indexOf(searchQuery.value.toLowerCase()) != -1
          );
        });
      } else if (filterValue.value && contents.value) {
        return (
          (filterValue.value.length &&
            contents.value.filter((topic) =>
              filterValue.value.includes(topic.category)
            )) ||
          contents.value
        );
      } else {
        return contents.value;
      }
    });

    const levelHandleClick = (level) => {
      questions.value = [];
      levelActive.value = level.id;
      let questIdsArray = level.questionIds.map((ids) => ids);
      questIdsArray.forEach((element) => {
        let data = questionsContent.value.find((x) => x.id === element);
        questions.value.push(data);
      });
      questions.value.sort(function (a, b) {
        return a.order - b.order;
      });
      showDialogQuestions.value = false;
      showDialogLevel.value = true;
      titleModal.value = "Level";
      type.value = "Update";
      levelForm.value = level;
      /*   levelContentActive.value = level.levelContentId;
      router.push({
        query: {
          unit_id: unitActive.value,
          lev_active: levelContentActive.value,
          level_id: level.id,
        },
      });
      questions.value = data
      orderNumber.value = level.order; */
    };

    const updateQuestions = () => {
      questions.value.forEach(async (element, index) => {
        element.order = null;
        firebase
          .firestore()
          .collection("Question")
          .doc(element.id)
          .update({ order: index })
          .catch((error) => {
            console.log(error);
          });
      });
      let arr = questions.value.map((element) => element.id);
      firebase
        .firestore()
        .collection("LevelContent")
        .doc(levelActive.value)
        .update({ questionIds: arr })
        .catch((error) => {
          console.log(error);
        });
    };
    const updateLev = () => {
      contents.value.forEach(async (element, index) => {
        console.log(element.id, element.title, index);
        element.order = index;
        firebase
          .firestore()
          .collection("LevelContent")
          .doc(element.id)
          .update({ order: index })
          .catch((error) => {
            console.log(error);
          });
      });
    };
    const updateUn = () => {
      units.value.forEach(async (element, index) => {
        element.order = index;
        const updateRef = firebase
          .firestore()
          .collection("Units")
          .doc(element.id);

        updateRef.update({ order: index }).catch((error) => {
          console.log(error);
        });
      });
    };

    const hideDialog = () => {
      showDialogUnit.value = false;
      showDialogLevel.value = false;
      showDialogQuestions.value = false;
      titleModal.value = "modal";
      type.value = "none";
      questForm.value = {
        docId: "",
        id: "",
        createdAt: "",
        levelId: "",
        unitId: "",
        levelQuestions: [],
      };
      unitForm.value = { title: "", isLive: false };
      levelForm.value = {
        title: null,
        createdAt: DateTime.now().toMillis(),
        isLive: false,
        category: null,
        questionIds: [],
      };
    };
    const openDialogAddUnit = async () => {
      showDialogUnit.value = true;
      titleModal.value = "Unit";
      type.value = "Add";

      unitForm.value = { title: "", isLive: false };
    };
    const openDialogUpdateUnit = (u) => {
      showDialogUnit.value = true;
      titleModal.value = "Unit";
      type.value = "Update";

      unitForm.value = u;
    };
    const openDialogFilter = () => {
      showCheckbox.value = true;
    };
    const openDialogAddLevel = () => {
      showDialogQuestions.value = false;
      showDialogLevel.value = true;
      titleModal.value = "Level";
      type.value = "Add";
      questionActive.value = null;
      levelForm.value = {
        title: null,
        createdAt: DateTime.now().toMillis(),
        isLive: false,
        category: null,
        questionIds: [],
      };
    };
    const openDialogUpdateLevel = (l) => {
      showDialogQuestions.value = false;
      showDialogLevel.value = true;
      titleModal.value = "Level";
      type.value = "Update";
      levelForm.value = l;
    };
    const openDialogUpdateQuestions = (l) => {
      showDialogLevel.value = false;
      console.log(l.isLive);
      questionActive.value = l.id;
      router.push({
        query: {
          unit_id: unitActive.value,
          lev_active: levelContentActive.value,
          level_id: levelActive.value,
          question_id: l.id,
        },
      });
      showDialogQuestions.value = true;
      titleModal.value = "Questions";
      type.value = "Update";
      if (l.isLive === null || l.isLive === undefined) {
        console.log("checked is null");
        l.isLive = true;
      }
      questForm.value = l;
      questForm.value.id = l.id;
      questForm.value.levelId = levelActive;
      let levelsList = levelsList;
    };
    const openDialogAddQuestions = () => {
      showDialogQuestions.value = true;
      showDialogLevel.value = false;
      titleModal.value = "Questions";
      levelActive.value;
      type.value = "Add";
      questForm.value = {
        mediaItemId: null,
        question: null,
        randomize: true,
        isLive: false,
        image: null,
        answers: [{}, {}, {}, {}],
        levels: [],
      };
    };

    const createUnit = (newUnit) => {
      let max =
        units.value && units.value.length
          ? units.value.slice().sort((a, b) => b.order - a.order)[0].order + 1
          : 0;
      newUnit.createdAt = Date.now();
      newUnit.order = max;

      db.collection("Units")
        .add(newUnit)
        .catch((e) => console.log(e));
    };
    const createQuest = async (newQuest) => {
      showDialogLevel.value = false;
      db.collection("LevelContent")
        .doc(levelContentActive.value)
        .update({ levelId: levelActive.value })
        .catch((e) => console.log(e));
      let ques = contents.value.find((x) => x.id == levelContentActive.value);
      let max =
        ques.levelQuestions && ques.levelQuestions.length
          ? ques.levelQuestions.slice().sort((a, b) => b.order - a.order)[0]
              .order + 1
          : 0;

      newQuest.order = max;
      questions.value = ques.levelQuestions
        ? [...ques.levelQuestions, newQuest]
        : [newQuest];

      let levelQuestions = questions.value;
      db.collection("LevelContent")
        .doc(levelContentActive.value)
        .update({ levelQuestions })
        .catch((e) => console.log(e));
    };
    const changeLevel = async (doc, value) => {
      console.log(doc, value);
      value.id = doc;
      questions.value.push(value);
      forceRerender();
      hideDialog();
    };
    const cloneLevel = async (id, value) => {
      value.id = id;
      hideDialog();
    };
    const updateUnit = (updUnit, id) => {
      updUnit.updatedAt = Date.now();

      db.collection("Units")
        .doc(id)
        .update(updUnit)
        .catch((e) => console.log(e));
    };
    const deleteUnit = (id) => {
      db.collection("Units")
        .doc(id)
        .delete()
        .catch((e) => console.log(e));
    };
    const createLevel = (id) => {
      let max =
        contents.value && contents.value.length
          ? contents.value.slice().sort((a, b) => b.order - a.order)[0].order +
            1
          : 0;
      console.log(max);
      let order = null;
      db.collection("LevelContent").doc(id).update({ order: order });
    };
    const moveLevel = (newLevel, unitId, levelId, unitIdToMove) => {
      let unit = units.value.find((x) => x.id == unitId);
      let max =
        unit.levels && unit.levels.length
          ? unit.levels.slice().sort((a, b) => b.order - a.order)[0].order + 1
          : 0;

      newLevel.order = max;

      levelsList.value = unit.levels ? [...unit.levels, newLevel] : [newLevel];

      let levels = levelsList.value;

      db.collection("Units")
        .doc(unitId)
        .update({ levels })
        .then(() => {})
        .then(() => {
          const unit = units.value.find((x) => x.id == unitIdToMove);
          levelsList.value = unit.levels.filter((x) => x.id !== levelId);

          let levels = levelsList.value;

          db.collection("Units").doc(unitIdToMove).update({ levels });
        });
    };
    const updateLevel = async () => {};

    const deleteQuest = (id, idQuest) => {
      let data = contents.value.find((x) => x.id === levelActive.value);
      let idx = data.questionIds.indexOf(id);
      data.questionIds.splice(idx, 1);
      db.collection("LevelContent")
        .doc(levelActive.value)
        .update({ questionIds: data.questionIds });

      hideDialog();
      let idxElement = questions.value.findIndex((object) => {
        return object.id === idQuest;
      });
      questions.value.splice(idxElement, 1);
      questionActive.value = null;
    };
    const closeContent = () => {
      hideDialog();
    };
    onMounted(() => {
      getOptions();
      getMyValue();
    });

    const deleteLevel = () => {
      showDialogLevel.value = false;
      hideDialog();
    };

    return {
      unitList,
      rendered,
      contents,
      units,
      forceRerender,
      componentKey,
      updateLev,
      renderedOpenQuestion,
      levelsList,
      unitActive,
      levelActive,
      cloneLevel,
      renderedQuestions,
      questionActive,
      levelContentActive,
      questions,
      orderNumber,
      options,
      /*   isRouterString, */
      unitForm,
      levelForm,
      moveLevel,
      showDialogQuestions,
      showCheckbox,
      showDialogUnit,
      showDialogLevel,
      titleModal,
      questForm,
      filterContent,
      type,
      deleteQuest,
      openDialogUpdateQuestions,
      createQuest,
      levelHandleClick,
      renderedLevels,
      updateQuestions,
      closeContent,
      hideDialog,
      searchQuery,
      clearData,
      openDialogAddUnit,
      openDialogAddQuestions,
      openDialogUpdateUnit,
      openDialogAddLevel,
      openDialogUpdateLevel,
      createUnit,
      openDialogFilter,
      questionsContent,
      deleteUnit,
      getMyValue,
      updateUnit,
      filterValue,
      updateUn,
      createLevel,
      updateLevel,
      getOptions,
      deleteLevel,
      changeLevel,
      readyToDelete,
    };
  },
  watch: {
    questionsType: function (val) {
      if (val) {
        this.$refs.modal2.open({});
      }
    },
  },
  methods: {
    fetchChanges(data) {
      this.questionsType = data.type;
      console.log(this.questionsType);
    },
    addQuestionTypeFunc() {
      this.$refs.modal.open({});
    },
  },
};
</script>

<style scoped>
table {
  padding: 40px;
}
.align-top {
  max-width: 300px;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media screen and (max-width: 600px) {
  .screen-media {
    min-height: 2100px;
    min-width: 1100px;
  }
}
.nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
.active-li {
  border: 1px solid #eda81c;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.icon {
  border-radius: 50%;
  background: #eda81c;
  font-size: 14px;
}

.edit {
  display: none;
  cursor: pointer;
}

.active-li:hover .edit {
  display: block;
}

@media screen and (max-width: 500px) {
  .span {
    display: none;
  }
}

.sortable-ghost {
  visibility: hidden;
}

.btn-add {
  font-size: 14px;
  color: #51a045;
}
</style>
