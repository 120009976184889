<template>
  <div class="w-full">
    <div class="flex flex-row px-7">
      <div class="edit ml-auto mr-5 block"></div>
    </div>

    <div
      class="bg-mywhite m-auto px-10 py-8 rounded-xl max-w-7xl overflow-scroll"
    >
      <div class="w-full h-3xl imageblock overflow-scroll">
        <div class="flex flex-wrap -mx-4 overflow-scroll">
          <div
            class="my-4 px-4 w-1/6 overflow-scroll flex flex-col"
            v-for="item of mediaLibrary"
            :key="item"
            @click="setImage(item.src)"
          >
            <div
              @click="handleClickAnswImg"
              class="w-full h-full flex flex-col"
            >
              <img
                class="w-47 h-44 rounded-lg"
                :src="require(`@/assets/${item.src}.jpeg`)"
              />
            </div>
          </div>
          <div class="ml-3" v-if="!isImages">
            <div
              class="flex justify-center mt-3 bg-gray-100 rounded-lg w-44 h-44"
            >
              <div
                class="rounded-lg max-h-52 overflow-hidden scale-125"
                :style="{
                  background: 'left/100% no-repeat url(' + answerImage + ')',
                }"
              >
                <div>
                  <div class="flex flex-col" @dragover.prevent @drop.prevent>
                    <img
                      @click="handleClickAnswImg()"
                      v-if="!answerImage"
                      class="m-auto mt-11 mb-4 h-8 w-9 max-h-52"
                      ref="img"
                      :src="require('@/assets/form-image1.png')"
                    />
                    <!--  <input
                            type="file"
                            ref="imageAnsw"
                            accept="image/png, image/gif, image/jpeg"
                            style="visibility: hidden"
                            class="bg-transparent"
                            multiple
                            @change="uploadFileAnswer"
                          /> -->
                    <img
                      @click="handClickAnswer(index)"
                      v-if="!answerImage"
                      class="m-auto h-8 w-9 max-h-52"
                      ref="img"
                      :src="require('@/assets/uploadImage.png')"
                    />
                    <input
                      ref="image"
                      style="visibility: hidden"
                      @change="hanldeChange"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <my-dialog v-model:show="showDialogGalery">
        <chose-image-popup
          :typeImage="typeImg"
          :indexImage="index"
          @addQuestExpImg="addQuestExpImg"
        >
        </chose-image-popup>
      </my-dialog>
      <my-dialog v-model:show="showDialogChoseImageAnswer">
        <add-image-answer
          :type="typeChoose"
          :crop="crop"
          :index="index"
          @update="uploadFile"
        >
        </add-image-answer>
      </my-dialog>
    </div>
  </div>
</template>
<script>
import AddImageAnswer from "./addImageAnswer";
import ChoseImagePopup from "./imageModal";
import MyDialog from "@/components/MyDialog";
export default {
  props: ["cropp", "typeImage", "indexImage", "typeSnippy", "isNoImage"],
  name: "add-questionlevel",
  data() {
    return {
      showDialogImage: false,
      searchQuery: "",
      mediaLibrary: [],
      showDialogGalery: false,
      showDialogChoseImageAnswer: false,
      type: "",
      isImages: false,
      typeChoose: null,
      answerImage: null,
      typeImg: null,
      typePage: null,
      index: null,
      crop: null,
    };
  },
  components: {
    AddImageAnswer,
    ChoseImagePopup,
    MyDialog,
  },
  methods: {
    setImage(i) {
      if (
        this.$props.typeSnippy === "ordering_question" ||
        this.$props.typeSnippy === "multiselect_questions" ||
        this.$props.typeSnippy === "matching_questions"
      ) {
        this.$emit("addOrdering", i);
      } else {
        this.$emit("add", i, this.$props.indexImage);
      }
    },
    uploadFile(newImage, file) {
      this.$emit("uploadFileCheckImage", newImage, file);
      this.showDialogChoseImageAnswer = false;
    },
    handClickAnswer(ind) {
      /* imageAnsw.value.click();
      state.index = ind;
      console.log(ind); */
      this.index = ind;
      this.showDialogChoseImageAnswer = true;
      this.typeChoose = "Add";
      this.crop = this.$props.cropp ? this.$props.cropp : 5 / 4;
    },
    addQuestExpImg(id, link, ind) {
      this.$emit("addQuestExpImg", id, link, ind);
      this.showDialogGalery = false;
    },
    handleClickAnswImg() {
      this.showDialogGalery = true;
      this.typeImg = "answerExpalinImage";
      this.index = this.$props.indexImage;
    },
  },
  async created() {
    this.isImages = this.$props.isNoImage;
    this.mediaLibrary = [
      { id: 1, src: "answer_type_1" },
      { id: 2, src: "answer_type_2" },
      { id: 3, src: "answer_type_3" },
      { id: 4, src: "answer_type_4" },
      { id: 5, src: "answer_type_5" },
      { id: 6, src: "answer_type_6" },
      { id: 7, src: "answer_type_7" },
      { id: 8, src: "answer_type_8" },
      { id: 9, src: "answer_type_9" },
      { id: 10, src: "answer_type_10" },
    ];
  },
};
</script>
<style>
.inp {
  width: 300px;
}
.imageblock {
  height: 500px;
  min-width: 1200px;
  overflow: scroll;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
