<template>
  <div class="w-full p-16">
    <div>
      <div class="w-full flex flex-col md:flex-row">
        <div class="w-3/4">
          <div class="pr-10 w-4/5">
            <button
              class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
              @click="deleteMainImage"
            >
              <p class="m-auto">✕</p>
            </button>
            <label
              class="flex font-serif dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold"
              >Image</label
            >
            <div class="flex justify-center mt-2 bg-gray-100 rounded-lg h-42">
              <div
                class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                :style="{
                  background:
                    'left/100% no-repeat url(' + dataLevel.image + ')',
                }"
              >
                <VuePlayerVideo
                  v-if="checkVideo"
                  :src="question.image"
                  :colors="['#ce8314']"
                  :autoplay="false"
                  class="w-47 max-h-52"
                />
                <div>
                  <div v-if="!dataLevel.image" @dragover.prevent @drop.prevent>
                    <img
                      @click="handleClick"
                      class="m-auto mt-5 mb-7 max-h-20 w-10 max-h-52"
                      ref="img"
                      :src="require('@/assets/form-image1.png')"
                    />
                    <!--   <input
                      type="file"
                      ref="AddFile"
                      accept="image/png, image/gif, image/jpeg"
                      style="visibility: hidden"
                      class="bg-transparent"
                      multiple
                      @change="uploadFile"
                    /> -->
                    <img
                      @click="handClick"
                      class="m-auto mt-2 max-h-20 w-10 max-h-52"
                      ref="img"
                      :src="require('@/assets/uploadImage.png')"
                    />
                  </div>
                  <img v-else class="m-auto w-2xl h-48" ref="img" />
                  <input
                    ref="image"
                    style="visibility: hidden"
                    @change="hanldeChange"
                    type="file"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mb-6 mt-2 pr-10 w-4/5">
            <label class="flex dark:text-gray-50 text-gray-700 font-serif text-sm font-bold mb-2"
              >Title</label
            >
            <div class="w-full flex flex-col">
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  v-model="dataLevel.title"
                  class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                  placeholder="Your message"
                  :class="[
                    'w-full',
                    treckField && !dataLevel.title
                      ? 'border-red-500 border-b text-red-500'
                      : 'bg-mywhite',
                  ]"
                />
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <ExclamationCircleIcon
                    v-if="!dataLevel.title && treckField"
                    class="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
            <Combobox as="div" v-model="selectedRecipe">
              <ComboboxLabel
                class="flex mt-2 dark:text-gray-50 text-gray-700 font-serif text-sm font-bold mb-2"
                >Recipe</ComboboxLabel
              >
              <div class="relative mt-1">
                <ComboboxInput
                  class="w-full border rounded-lg border-gray-300 bg-mywhite py-2 pl-3 pr-10 shadow-sm border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none sm:text-sm"
                  @change="query = $event.target.value"
                  :display-value="(recipe) => recipe?.title"
                />
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                  <SelectorIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>

                <ComboboxOptions
                  v-if="filteredRecipe"
                  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-mywhite py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-1 focus:outline-none sm:text-sm"
                >
                  <ComboboxOption
                    v-for="recipe in filteredRecipe"
                    :key="recipe.id"
                    :value="recipe"
                    as="template"
                    v-slot="{ active, selected }"
                  >
                    <li
                      :class="[
                        'relative cursor-default select-none py-2 pl-3 pr-9  border-b border-gray-200',
                        active ? 'bg-mygreen text-mywhite' : 'text-gray-900',
                      ]"
                    >
                      <span
                        class="w-full dark:text-gray-900 text-left"
                        :class="['block truncate', selected && 'font-semibold']"
                      >
                        {{ recipe.title }}
                      </span>

                      <span
                        v-if="selected"
                        :class="[
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-mywhite' : 'text-green-600',
                        ]"
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>
          <div class="flex flex-row">
            <div class="mt-2">
              <label for="toggleA" class="flex items-center cursor-pointer">
                <div
                  class="mr-8 dark:text-gray-50 w-full text-left font-bold text-gray-700 font-medium"
                >
                  Is Live?
                </div>
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input
                    type="checkbox"
                    id="toggleA"
                    class="sr-only"
                    v-model="dataLevel.isLive"
                  />
                  <!-- line -->
                  <div class="block bg-gray-200 w-14 h-8 rounded-full"></div>
                  <!-- dot -->
                  <div
                    class="dot absolute left-1 top-1 bg-mywhite w-6 h-6 rounded-full transition"
                  ></div>
                </div>
                <!-- label -->
              </label>
            </div>
            <div class="mb-6" v-if="type == 'Add'">
              <button
                class="ml-3 font-sans w-24 h-12 px-3 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
                @click="updateDish"
                v-shortkey.once="['ctrl', 'enter']"
                @shortkey="updateDish()"
              >
                Add
              </button>
            </div>
            <div class="mb-6 ml-2 flex flex-row" v-if="type == 'Update'">
              <button
                class="font-sans h-12 w-24 max-w-16 px-3 font-bold font-sans text-red-400 transition-colors duration-150 rounded-full border border-red-400 focus:border-current"
                @click="showDialogoConfirm = true"
              >
                Delete
              </button>
            </div>
            <div class="mb-6" v-if="type == 'Update'">
              <button
                class="w-24 ml-2 font-sans h-12 px-3 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
                @click="updateDish"
                v-shortkey.once="['ctrl', 'enter']"
                @shortkey="updateDish()"
              >
                <div
                  v-if="this.loading"
                  class="animate-spin rounded-full h-5 w-5 border-b-2 border-mywhite m-auto"
                ></div>
                <span v-else>{{ type }}</span>
              </button>
            </div>
          </div>
          <div class="flex w-2/3">
            <fieldset class="flex w-full">
              <legend class="text-sm font-bold dark:text-gray-50 text-gray-700 font-serif  pr-7">Filter</legend>
              <div
                class="w-full mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
              >
                <div
                  v-for="(person, personIdx) in people"
                  :key="personIdx"
                  class="relative flex items-start py-3"
                >
                  <div class="min-w-0 flex-1 text-sm">
                    <label
                      :for="`person-${person.id}`"
                      class="dark:text-gray-50 select-none font-medium text-gray-700"
                      >{{ person.name }}</label
                    >
                  </div>
                  <div class="ml-3 flex h-5 items-center">
                    <input
                      :id="`person-${person.id}`"
                      :name="`person-${person.id}`"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      v-model="dataLevel.filters"
                      :value="person.value"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="w-2/3">
          <div class="bg-white flex justify-center items-center mb-3">
            <div class="w-full relative space-y-3">
              <Combobox as="div">
                <ComboboxLabel
                  class="flex text-gray-700 dark:text-gray-50 font-serif text-sm font-bold mb-2"
                  >Levels</ComboboxLabel
                >
                <div class="relative mt-1">
                  <ComboboxInput
                    id="comboboxFocusing"
                    class="w-full border border-gray-300 rounded-lg bg-mywhite py-2 pl-3 pr-10 shadow-sm border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none sm:text-sm"
                    @change="searchTerm = $event.target.value"
                    :display-value="(level) => level?.id"
                  />
                  <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>

                  <ComboboxOptions
                    v-if="searchItems"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-mywhite py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-1 focus:outline-none sm:text-sm"
                  >
                    <ComboboxOption
                      v-for="level in searchItems"
                      :key="level.id"
                      :value="level"
                      as="template"
                      v-slot="{ active, selected }"
                      v-shortkey.once="['enter']"
                      @shortkey="selectItem(level)"
                      @click="selectItem(level)"
                      @focus="() => (comboboxFocusing = level.id)"
                      @blur="
                        () =>
                          comboboxFocusing === level.id &&
                          (comboboxFocusing = void 0)
                      "
                    >
                      <li
                        :class="[
                          'relative flex flex-row cursor-default select-none py-2 pl-3 pr-9  border-b border-gray-200',
                          active ? 'bg-mygreen text-mywhite' : 'text-gray-900',
                        ]"
                      >
                        <span
                          class="w-2/3 font-serif font-600 text-left"
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ level.title }}
                        </span>
                        <span
                          v-if="level.category"
                          class="w-32 font-serif ml-1 font-600 text-left"
                          :class="[active ? 'text-mywhite' : 'text-gray-400']"
                        >
                          {{ level.category }}
                        </span>

                        <span
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-mywhite' : 'text-gray-400',
                          ]"
                        >
                          <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>
              <!--   <div class="flex flex-row justify-center">
                <input
                  v-model="searchTerm"
                  placeholder="Type level name"
                  class="p-3 mb-0.5 h-10 w-64 border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none"
                />
                <button
                  @click="clearField"
                  class="h-10 w-10 border-b border-gray-300"
                >
                  &#215;
                </button>
              </div>

              <ul
                v-if="searchItems.length && searchTerm"
                class="w-full absolute z-10 flex-auto rounded bg-mywhite border border-gray-300 px-4 py-2"
              >
                <li
                  v-for="i in searchItems"
                  :key="i.title"
                  @click="selectItem(i)"
                  class="cursor-pointer hover:bg-gray-100 p-1 flex border-b"
                >
                  <div class="flex w-full">
                    <p class="flex">{{ i.title }}</p>
                  </div>

                  <div class="flex flex-row">
                    <div class="icon ml-3 max-h-8">
                      <span><i class="fas fa-plus"></i> </span>
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>
          </div>

          <draggable
            class="dragArea list-group w-full"
            :list="dataChosenLevelsIds"
            @dragend="updateIndexLevel"
          >
            <div
              class="list-group-item dark:text-gray-50 flex flex-row p-3 font-bold border border-gray-200 rounded-md mb-1 w-full h-12 m-auto"
              v-for="(level, index) in dataChosenLevelsIds"
              :key="index"
            >
              <div class="flex flex-row">
                <div class="icon mr-2 max-h-8">
                  <span><i class="fas fa-list-ul"></i> </span>
                </div>
              </div>

              <div
                class="flex flex-row truncate hover:text-clip justify-left w-full"
              >
                <p class="ml-3 mr-3 truncate dark:text-gray-50 hover:text-clip text-left w-1/2">
                  {{ searchedLevelName(level) }}
                </p>
                <p class="font-normal dark:text-gray-50 text-xm text-left w-1/3">
                  {{ searchedLevelCat(level) }}
                </p>
                <div v-if="searchedLevelStatus(level) != true">
                  <p class="font-normal text-xm text-left w-1/2">
                    <img src="@/assets/warning.png" class="max-h-10 pt-1" />
                  </p>
                </div>
              </div>

              <div class="flex flex-row">
                <div class="icon ml-3 max-h-8">
                  <span
                    ><i
                      @click="levelHandleClick(index)"
                      class="fas fa-trash p-2"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <my-dialog v-model:show="showDialogoConfirm">
      <confirmation-popup
        @delete="deleteDish"
        @cancel="showDialogoConfirm = false"
      ></confirmation-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogChoseImage">
      <add-image-main :type="typeChoose" @update="uploadFile"> </add-image-main>
    </my-dialog>
    <my-dialog v-model:show="showDialogImage">
      <chose-image-popup
        :typeImage="typeImg"
        :indexImage="index"
        @add="addImage"
      >
      </chose-image-popup>
    </my-dialog>
  </div>
</template>

<script>
/* import { createToast } from "mosha-vue-toastify"; */
import { toRefs, reactive, computed } from "vue";
import imageCompression from "browser-image-compression";
/* import { db } from "@/firebase/config"; */
import { ref } from "vue";
import { db } from "@/firebase/config";
import MyDialog from "@/components/MyDialog";
import ChoseImagePopup from "../units/modals/imageModal.vue";
import useStorageLibrary from "@/composables/useStorageLibrary";
import { VueDraggableNext } from "vue-draggable-next";
import getCollectionUnit from "@/composables/getCollectionUnits";
import getCollectionFiltered from "@/composables/getCollectionFiltered";
import { createToast } from "mosha-vue-toastify";
import confirmationPopup from "@/components/confirmatioPopup.vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { CheckIcon, SelectorIcon, PlusSmIcon } from "@heroicons/vue/solid";
import { getStorage, getMetadata } from "firebase/storage";
import { ref as storageRef } from "firebase/storage";
import AddImageMain from "./addImageMain";
import "vue-loading-overlay/dist/vue-loading.css";
//import debounce from "lodash-es/debounce";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
export default {
  name: "level-form",
  props: {
    type: String,
    level: Object,
    levels: Object,
  },
  components: {
    Combobox,
    CheckIcon,
    AddImageMain,
    ExclamationCircleIcon,
    SelectorIcon,
    confirmationPopup,
    PlusSmIcon,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    MyDialog,
    ChoseImagePopup,
    draggable: VueDraggableNext,
  },
  setup(props, context) {
    const state = reactive({
      levelsArr: [],
      LevelsIds: [],
      filters: [],
      showDialogImage: false,
      people: [
        { id: 1, name: "Vegetarian", value: "vegetarian" },
        { id: 2, name: "Vegan", value: "vegan" },
        { id: 3, name: "Dairy Free", value: "dairy-free" },
        { id: 4, name: "Gluten Free", value: "gluten-free" },
      ],
      category: null,
      file: null,
      showDialogoConfirm: false,
      Image: null,
      typeImg: null,
      isSaved: false,
      tags: [],
      showDialogChoseImage: false,
      typeChoose: null,
      treckField: false,
      answerImage: null,

      wordsPriority: {
        default: {
          ingredients: 20,
          shopping: 10,
          making: 0,
        },
      },
      wordsActive: [
        { name: "ingredients", order: 1 },
        { name: "shopping", order: 2 },
        { name: "making", order: 3 },
      ],
      isDropd: false,
      isLoading: false,
      fullPage: true,
    });
    const AddFile = ref([]);
    const { documents: recipe } = getCollectionUnit("Recipes");
    const { documents: contents } = getCollectionFiltered("LevelContent");
    const { url, uploadImage } = useStorageLibrary();
    const dataLevel = computed(() => {
      return props.level;
    });
    const comboboxFocusing = ref();
    const selectedLeve = ref();
    const query = ref("");
    const selectedRecipe = ref(null);
    const filteredRecipe = computed(() =>
      query.value === ""
        ? recipe.value
        : recipe.value.filter((recipe) => {
            return recipe.title
              .toLowerCase()
              .includes(query.value.toLowerCase());
          })
    );
    const searchedRec = () => {
      let data = recipe.value.find((product) => {
        return product.id === dataLevel.value.recipe;
      });
      selectedRecipe.value = data;
    };
    const dataChosenLevels = computed(() => {
      searchedRec();
      return props.levels;
    });
    const dataChosenLevelsIds = computed(() => {
      return props.level.levels;
    });

    const searchedLevelName = (id) => {
      let docs = dataChosenLevels.value.find((product) => product.id === id);
      if (docs != undefined) {
        return docs.title;
      }
    };
    const addFilter = () => {
      dataLevel.value.filters = [];
    };
    const searchedLevelCat = (id) => {
      let docs = dataChosenLevels.value.find((product) => product.id === id);
      if (docs != undefined) {
        return docs.category;
      }
    };

    const searchedLevelStatus = (id) => {
      let docs = dataChosenLevels.value.find((product) => product.id === id);
      if (docs != undefined) {
        return docs.isLive;
      }
    };

    let searchTerm = ref("");
    let recipeName = ref("");
    let searchDish = ref("");

    const searchItems = computed(() => {
      if (searchTerm.value === "") {
        return contents.value;
      }
      let matches = 0;
      return contents.value.filter((item) => {
        if (
          item.title.toLowerCase().includes(searchTerm.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return item;
        }
      });
    });

    const searchDishes = computed(() => {
      if (searchDish.value === "") {
        return [];
      }
      let matches = 0;
      return recipe.value.filter((item) => {
        if (
          item.title.toLowerCase().includes(searchDish.value.toLowerCase()) &&
          matches < 10
        ) {
          matches++;
          return item;
        }
      });
    });
    const levelHandleClick = (i) => {
      dataLevel.value.levels.splice(i, 1);
      dataChosenLevels.value.splice(i, 1);
    };
    const selectItem = (item) => {
      if (comboboxFocusing.value === item.id) {
        dataChosenLevels.value.push({
          id: item.id,
          title: item.title,
          category: item.category,
        });
        dataLevel.value.levels.push(item.id);

        searchTerm.value = "";
      }
    };
    const selectRecipe = (item) => {
      recipeName.value = null;
      dataLevel.value.recipe = item.id;
      searchDish.value = "";
    };
    const clearField = () => {
      searchTerm.value = "";
    };
    const clearFieldDish = () => {
      searchDish.value = "";
      dataLevel.value.recipe = null;
      recipeName.value = null;
    };
    let selectedItem = ref("");

    const deleteMainImage = () => {
      dataLevel.value.image = null;
      dataLevel.value.mediaItemId = null;
      state.Image = null;
      dataLevel.value.media = null;
    };
    const addImage = (id, link) => {
      const storage = getStorage();
      const forestRef = storageRef(storage, `media-library/${id}/original`);

      // Get metadata properties
      getMetadata(forestRef)
        .then((metadata) => {
          dataLevel.value.media = {
            mediaItemId: id,
            mediaLink: link,
          };
          let type = metadata.contentType.split("/")[1];
          if (type === "jpeg" || type === "jpg" || type === "png") {
            dataLevel.value.media.mediaType = "image";
          } else if (type === "gif") {
            dataLevel.value.media.mediaType = "gif";
          } else {
            dataLevel.value.media.mediaType = "video";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          if (dataLevel.value.media.mediaType === "video") {
            createToast(
              {
                title: "Failure",
                description: "You can't select a video here",
              },
              {
                type: "danger",
                position: "top-center",
                transition: "bounce",
              }
            );
          } else {
            (dataLevel.value.mediaItemId = id),
              (state.filePath = link),
              (dataLevel.value.image = link),
              (state.Image = link),
              (state.showDialogImage = false);
          }
        });
    };
    const handleClick = () => {
      state.showDialogImage = true;
      state.typeImg = "questionImage";
    };
    const dragFile = async (e) => {
      let data = e.dataTransfer.files[0];
      const compressedFile = await imageCompression(data, {
        maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
        maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
        useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
        // advanced options
        // optional, see https://stackoverflow.com/a/32490603/10395024
        initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
      });
      state.file = compressedFile;
      createImage();
    };
    const uploadFile = async (dataD, file) => {
      state.tags = dataD.tags;
      if (file.type === "image/gif") {
        const selected = file;

        if (selected) {
          state.file = selected;
          state.filePath = URL.createObjectURL(state.file);
        }
      } else {
        let data = file;
        const compressedFile = await imageCompression(data, {
          maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
          maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
          // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
          maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
          useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
          // advanced options
          // optional, see https://stackoverflow.com/a/32490603/10395024
          initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
        });
        state.file = compressedFile;
      }
      createImage();
    };
    const handClick = () => {
      /*   AddFile.value.click(); */
      state.showDialogChoseImage = true;
      state.typeChoose = "Add";
    };

    const updateDish = () => {
      if (dataLevel.value.isLive) {
        state.treckField = true;
      } else {
        state.treckField = false;
      }
      if (!dataLevel.value.title) {
        createToast(
          {
            title: "Incorect data",
            description: "There are empty field",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      } else {
        state.isLoading = true;
        if (props.type === "Update") {
          if (selectedRecipe.value) {
            dataLevel.value.recipe = selectedRecipe.value.id;
          }
          db.collection("Dish")
            .doc(dataLevel.value.id)
            .update(dataLevel.value)
            .then(() => {
              state.isLoading = false;
              createToast(
                {
                  title: "Success",
                  description: "Dish successfully updated",
                },
                {
                  type: "success",
                  position: "top-right",
                  transition: "bounce",
                }
              );
            })
            .catch((e) => console.log(e));
        } else {
          if (selectedRecipe.value) {
            dataLevel.value.recipe = selectedRecipe.value.id;
          }
          db.collection("Dish")
            .add(dataLevel.value)
            .then((a) => {
              state.isLoading = false;
              context.emit("create", a.id),
                createToast(
                  {
                    title: "Success",
                    description: "Dish successfully added",
                  },
                  {
                    type: "success",
                    position: "top-center",
                    transition: "bounce",
                  }
                );
            });
        }
      }
    };
    const createImage = async () => {
      state.isLoading = true;
      let newImage = {
        type: "image",
        tags: state.tags,
        iconLink: null,
        title: "UploadedImage",
      };
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(state.file, "media-library/" + a.id);
          dataLevel.value.media = {
            mediaItemId: a.id,
          };
          const storage = getStorage();
          const forestRef = storageRef(
            storage,
            `media-library/${a.id}/original`
          );

          // Get metadata properties
          getMetadata(forestRef)
            .then((metadata) => {
              let type = metadata.contentType.split("/")[1];
              if (type === "jpeg" || type === "jpg" || type === "png") {
                dataLevel.value.media.mediaType = "image";
              } else if (type === "gif") {
                dataLevel.value.media.mediaType = "gif";
              }
            })
            .catch((error) => {
              console.log(error);
            });
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .then((info) => {
              state.isLoading = false;
              console.log(info, "infoooo"),
                (state.filePath = url.value),
                (dataLevel.value.image = url.value),
                (state.answerImage = url.value),
                (dataLevel.value.media.mediaLink = url.value);
              state.showDialogChoseImage = false;
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    };
    const deleteDish = () => {
      db.collection("Dish")
        .doc(dataLevel.value.id)
        .delete()
        .then(() => {
          state.showDialogoConfirm = false;
          context.emit("close");
        });
    };
    /*     let autoSave = debounce(() => {
      updateDish();
    }, 300);
    setTimeout(function () {
      watch(
        () => dataLevel.value,
        (selection, prevSelection) => {
          console.log(selection.id, "watched");
          console.log(prevSelection.id, "NOTWATCH");

          state.isSaved = true;
        },
        { deep: true },
        { immediate: false }
      );
    }, 3000); */

    return {
      ...toRefs(state),
      query,
      dataLevel,
      deleteDish,
      searchedLevelStatus,
      createImage,
      comboboxFocusing,
      searchDishes,
      recipe,
      deleteMainImage,
      selectedRecipe,
      filteredRecipe,
      handleClick,
      uploadFile,
      dragFile,
      updateDish,
      CheckIcon,
      SelectorIcon,
      selectRecipe,
      dataChosenLevels,
      AddFile,
      addFilter,
      selectedLeve,
      searchedLevelCat,
      PlusSmIcon,
      addImage,
      recipeName,
      handClick,
      clearField,
      searchedLevelName,
      clearFieldDish,
      searchedRec,
      //autoSave,
      dataChosenLevelsIds,
      searchDish,
      contents,
      levelHandleClick,
      searchTerm,
      searchItems,
      selectItem,
      selectedItem,
    };
  },
};
</script>

<style scoped>
.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.selected.highlighted {
  background-color: #f5f5f5 !important;
}
.image {
  height: 100px;
  width: 100px;
}
.fa-plus:before {
  content: "\f067";
  color: #bdbbb7;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-d {
  width: 315px;
}
button:disabled {
  background: transparent;
  color: green;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block {
  background-color: #0e0e0e;
}
</style>
