<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-4 flex flex-col max-h-96 scroll">
      <input
        v-model="searchQuery"
        class="mt-2 pl-2 border rounded-lg float-right mb-4"
        placeholder="Search"
      />
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
          
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white w-96">
                <tr
                  v-for="level in searchedProducts"
                  :key="level.id"
                  class="hover:bg-blue-100 hover:text-mywhite"
                >
                  <td
                    @click="openConfirm(level)"
                    class="whitespace-nowrap py-2 pl-4 cursor-pointer font-bold w-96 truncate pr-3 text-sm text-gray-500 sm:pl-6"
                  >
                    <p class="text-left">{{ level.title }}</p>
                  </td>
                  <td
                    @click="openConfirm(level)"
                    class="whitespace-nowrap py-2 cursor-pointer pl-4 w-96 truncate pr-3 text-sm text-gray-500 sm:pl-6"
                  >
                    <p class="text-left">{{ level.category }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed z-99 inset-0 overflow-y-auto">
          <div
            class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative bg-mywhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
              >
                <div>
                  <div
                    class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                  >
                    <DocumentDuplicateIcon
                      class="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Confirmation
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Do you definitely want to Add this level to '{{
                          levelData.title
                        }}' Dish
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full bg-blue-600 text-mywhite inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="confirm"
                    v-shortkey.once="['enter']"
                    @shortkey="confirm"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="open = false"
                    ref="cancelButtonRef"
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
import getCollectionUnit from "@/composables/getCollectionUnits";
import { ref, computed } from "vue";
import { db } from "@/firebase/config";
import { createToast } from "mosha-vue-toastify";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { DocumentDuplicateIcon } from "@heroicons/vue/outline";

export default {
  props: {
    questionId: String,
  },
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    DocumentDuplicateIcon,
  },
  setup(props, context) {
    const { documents: levelContent } = getCollectionUnit("Dish");
    const searchQuery = ref("");
    const open = ref(false);
    const levelData = ref({});
    const searchedProducts = computed(() => {
      if (levelContent.value) {
        return levelContent.value.filter((product) => {
          return (
            product.title
              .toLowerCase()
              .indexOf(searchQuery.value.toLowerCase()) != -1
          );
        });
      } else {
        return levelContent.value;
      }
    });
    const openConfirm = (level) => {
      console.log(level);
      open.value = true;
      levelData.value = level;
    };
    const confirm = () => {
      moveTo(levelData.value).then(() => {
        levelData.value = {};
      });
    };
    const moveTo = (level) => {
      level.levels.push(props.questionId);
      console.log(level);
      db.collection("Dish")
        .doc(level.id)
        .update(level)
        .then(() => {
          context.emit("add", level.id),
            createToast(
              {
                title: "Success",
                description: `Level Added to '${level.title}' dish`,
              },
              {
                type: "info",
                position: "top-center",
                transition: "bounce",
              }
            );
        })
        .catch((e) => console.log(e));
    };
    return {
      moveTo,
      confirm,
      searchedProducts,
      searchQuery,
      openConfirm,
      levelData,
      open,
      levelContent,
    };
  },
};
</script>
<!-- <script>
export default {
  props: ["typeImage","indexImage","typeSnippy"],
  name: "add-questionlevel",
  data() {
    return {
      showDialogImage: false,
      searchQuery: "",
      mediaLibrary: [],
      type: "",
    };
  },
  methods: {

  },
  async created() {
    this.mediaLibrary = [
      { id: 1, src: "answer_type_1" },
      { id: 2, src: "answer_type_2" },
      { id: 3, src: "answer_type_3" },
      { id: 4, src: "answer_type_4" },
      { id: 5, src: "answer_type_5" },
      { id: 6, src: "answer_type_6" },
      { id: 7, src: "answer_type_7" },
      { id: 8, src: "answer_type_8" },
      { id: 9, src: "answer_type_9" },
      { id: 10, src: "answer_type_10" },
    ];
  },
};
</script> -->
<style>
.inp {
  width: 300px;
}
.dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  z-index: 7 !important;
}
.imageblock {
  height: 500px;
  min-width: 1200px;
  overflow: scroll;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
