<template>
  <div class="w-full p-16">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :on-cancel="false"
      color="#51A045"
      :is-full-page="fullPage"
    />
    <form @submit.prevent>
      <div class="w-full flex flex-col md:flex-row">
        <div class="w-3/4">
          <div class="pr-10">
            <button
              class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
              @click="deleteMainImage"
            >
              <p class="m-auto">✕</p>
            </button>
            <label
              class="flex dark:text-gray-50 font-serif tracking-wide text-gray-700 text-base font-bold"
              >Image</label
            >
            <div class="flex justify-center mt-2 bg-gray-100 rounded-lg h-42">
              <div
                class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                :style="{
                  background:
                    'left/100% no-repeat url(' + dataLevel.image + ')',
                }"
              >
                <div>
                  <div v-if="!dataLevel.image" @dragover.prevent @drop.prevent>
                    <img
                      @click="handleClick"
                      class="m-auto mt-5 mb-7 max-h-20 w-10 max-h-52"
                      ref="img"
                      :src="require('@/assets/form-image1.png')"
                    />
                    <!--  <input
                      type="file"
                      ref="AddFile"
                      accept="image/png, image/gif, image/jpeg"
                      style="visibility: hidden"
                      class="bg-transparent"
                      multiple
                      @change="uploadFile"
                    /> -->
                    <img
                      @click="handClick"
                      class="m-auto mt-2 max-h-20 w-10 max-h-52"
                      ref="img"
                      :src="require('@/assets/uploadImage.png')"
                    />
                  </div>
                  <img v-else class="m-auto w-2xl h-48" ref="img" />
                  <input
                    ref="image"
                    style="visibility: hidden"
                    @change="hanldeChange"
                    type="file"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <label
              class="flex dark:text-gray-50 font-serif mt-2 mb-2 tracking-wide text-gray-700 text-base font-bold"
              >External Link</label
            >
            <div class="mb-6 pr-10 flex flex-row">
              <label
                class="flex dark:text-gray-50 text-gray-700 mr-6 font-serif text-sm font-bold mb-2"
                >Link</label
              >
              <input
                v-model="recipeSource.link"
                class="bg-white font-serif h-4 appearance-none border-b border-black w-full focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                type="text"
                placeholder="https://parsnip-io.web.app/recipes"
              />
            </div>
            <div class="mb-6 pr-10 flex flex-row">
              <label
                class="flex dark:text-gray-50 text-gray-700 mr-6 font-serif text-sm font-bold mb-2"
                >Title</label
              >
              <input
                v-model="recipeSource.title"
                class="bg-white font-serif h-4 appearance-none border-b border-black w-full focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                type="text"
              />
            </div>
          </div>
          <label
            class="flex font-serif mt-2 dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold"
            >Ingredients</label
          >
          <div class="pr-10">
            <draggable
              class="dragArea list-group w-full items-center mr-5"
              :list="dataIngredients"
              @dragend="updateLev"
              :disabled="whenClickInpute"
            >
              <div
                class="flex flex-row pt-2 pl-2 h-14 font-bold border border-gray-200 rounded-md mb-1 w-full m-1"
                v-for="(ing, index) in dataIngredients"
                :key="ing"
              >
                <div class="flex flex-row">
                  <div class="icon mr-2 mt-2">
                    <span><i class="fas fa-list-ul"></i> </span>
                  </div>
                </div>
                <div class="flex-col justify-center w-full mr-3">
                  <input
                    @mouseover="whenClickInpute = true"
                    @mouseleave="whenClickInpute = false"
                    placeholder="Name"
                    v-model="ing.name"
                    class="font-normal dark:text-gray-800 text-sm w-full h-9 border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none"
                  />
                </div>
                <div class="flex-col justify-center w-full">
                  <input
                    @mouseover="whenClickInpute = true"
                    @mouseleave="whenClickInpute = false"
                    placeholder="Quantity"
                    v-model="ing.quantity"
                    class="font-normal dark:text-gray-800 text-sm w-full h-9 border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none"
                  />
                </div>

                <div class="flex flex-row">
                  <div class="icon ml-3 mt-1">
                    <span
                      ><i
                        @click="StepHandleClick(index)"
                        class="fas fa-trash p-2"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
          <div>
            <p
              @click="addIngr"
              class="cursor-pointer mr-12 text-mygreen font-serif"
            >
              +Add ingredient
            </p>
          </div>
        </div>
        <div class="w-4/5">
          <div class="w-64">
            <div class="mb-6 pr-10 flex flex-row">
              <label
                class="flex dark:text-gray-50 text-gray-700 mr-7 font-serif text-sm font-bold mb-2 flex flex-row"
                >Title</label
              >
              <input
                v-model="dataLevel.title"
                class="bg-white font-serif appearance-none h-4 border-b border-black w-full focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                type="text"
              />
            </div>
            <div class="mb-6 pr-10 flex flex-row">
              <label
                class="flex dark:text-gray-50 text-gray-700 mr-2 font-serif text-sm font-bold mb-2"
                >Serving</label
              >
              <input
                v-model="dataLevel.servings"
                class="bg-white font-serif h-4 appearance-none border-b border-black w-full focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                type="text"
              />
            </div>
            <div class="mb-6 pr-10 flex flex-row">
              <label
                class="flex dark:text-gray-50 text-gray-700 mr-6 font-serif text-sm font-bold mb-2"
                >Time</label
              >
              <input
                v-model="dataLevel.time"
                class="bg-white font-serif h-4 appearance-none border-b border-black w-full focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                type="text"
              />
            </div>
            <div class="flex flex-row">
              <div class="mb-6" v-if="type == 'Add'">
                <button
                  class="ml-3 font-sans w-24 h-12 px-3 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
                  @click="updateDish"
                  v-shortkey.once="['ctrl', 'enter']"
                  @shortkey="updateDish()"
                >
                  Add
                </button>
              </div>
              <div class="mb-6 ml-2 flex flex-row" v-if="type == 'Update'">
                <button
                  class="font-sans h-12 w-24 max-w-16 px-3 font-bold font-sans text-red-400 transition-colors duration-150 rounded-full border border-red-400 focus:border-current"
                  @click="showDialogoConfirm = true"
                >
                  Delete
                </button>
              </div>
              <div class="mb-6" v-if="type == 'Update'">
                <button
                  class="w-24 ml-2 font-sans h-12 px-3 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
                  @click="updateDish"
                  v-shortkey.once="['ctrl', 'enter']"
                  @shortkey="updateDish()"
                >
                  <div
                    v-if="this.loading"
                    class="animate-spin rounded-full h-5 w-5 border-b-2 border-mywhite m-auto"
                  ></div>
                  <span v-else>{{ type }}</span>
                </button>
              </div>
            </div>
          </div>
          <label
            class="flex dark:text-gray-50 font-serif mt-2 tracking-wide text-gray-700 text-base font-bold"
            >Steps</label
          >
          <draggable
            class="dragArea list-group w-full"
            :list="dataReciep"
            @dragend="updateLev"
          >
            <div
              class="flex flex-row p-3 font-bold border border-gray-200 rounded-md mb-1 w-full m-1"
              v-for="(level, index) in dataReciep"
              :key="index"
            >
              <div class="flex flex-row">
                <div class="icon mr-2 my-auto">
                  <span><i class="fas fa-list-ul"></i> </span>
                </div>
              </div>

              <div class="flex-col justify-center w-full h-full">
                <textarea
                  data-expandable
                  v-model="dataReciep[index]"
                  :ref="textareaRef"
                  @input="resize(index)"
                  @focus="resize(index)"
                  placeholder="Step Description..."
                  class="font-normal dark:text-gray-800 resize-none text-xs w-full min-h-3 border-b border-gray-300 focus:outline-none focus:bg-white focus:border-none"
                ></textarea>
              </div>

              <div class="flex flex-row">
                <div class="icon ml-3 my-auto">
                  <span
                    ><i
                      @click="levelHandleClick(index)"
                      class="fas fa-trash p-2"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </draggable>
          <div>
            <p @click="addStep" class="cursor-pointer text-mygreen font-serif">
              +Add Step
            </p>
          </div>
        </div>
      </div>
    </form>
    <my-dialog v-model:show="showDialogoConfirm">
      <confirmation-popup
        @delete="deleteDish"
        @cancel="showDialogoConfirm = false"
      ></confirmation-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogImage">
      <chose-image-popup
        :typeImage="typeImg"
        :indexImage="index"
        @add="addImage"
      >
      </chose-image-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogChoseImage">
      <add-image-main :type="typeChoose" @update="uploadFile"> </add-image-main>
    </my-dialog>
  </div>
</template>

<script>
/* import { createToast } from "mosha-vue-toastify"; */
import { toRefs, reactive, computed, onMounted } from "vue";
/* import { db } from "@/firebase/config"; */
import { ref } from "vue";
import imageCompression from "browser-image-compression";
import { db } from "@/firebase/config";
import MyDialog from "@/components/MyDialog";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ChoseImagePopup from "../units/modals/imageModal.vue";
import confirmationPopup from "@/components/confirmatioPopup.vue";
import useStorageLibrary from "@/composables/useStorageLibrary";
import { createToast } from "mosha-vue-toastify";
import { VueDraggableNext } from "vue-draggable-next";
import { getStorage, getMetadata } from "firebase/storage";
import { ref as storageRef } from "firebase/storage";
import AddImageMain from "./addImageMain";
export default {
  name: "level-form",
  props: {
    type: String,
    reciep: Object,
    levels: Object,
  },
  components: {
    MyDialog,
    ChoseImagePopup,
    confirmationPopup,
    Loading,
    AddImageMain,
    draggable: VueDraggableNext,
  },
  setup(props, context) {
    const state = reactive({
      showDialogImage: false,
      recipeSource: {},
      category: null,
      file: null,
      whenClickInpute: false,
      typeChoose: null,
      showDialogChoseImage: false,
      isLoading: false,
      Image: null,
      showDialogoConfirm: false,
      typeImg: null,
      tags: [],
      itemRefs: [],
      answerImage: null,
      isDropd: false,
    });
    const AddFile = ref([]);
    const { url, uploadImage } = useStorageLibrary();
    const dataLevel = computed(() => {
      return props.reciep;
    });
    const dataReciep = computed(() => {
      return props.reciep.steps;
    });
    const recipeSource = computed(() => {
      if (props.reciep.recipeSource) {
        return props.reciep.recipeSource;
      } else {
        return {};
      }
    });
    const dataIngredients = computed(() => {
      return props.reciep.ingredients;
    });
    const dataChosenLevels = computed(() => {
      return props.levels;
    });
    let searchTerm = ref("");

    const resize = async (i) => {
      state.itemRefs = [];
      await textareaRef();
      let textarea = await state.itemRefs[i];
      textarea.style.minHeight = textarea.scrollHeight + "px";
      textarea.style.removeProperty("height");
    };
    const checkVideo = computed(() => {
      if (props.reciep.media) {
        if (props.reciep.media.mediaType) {
          if (props.reciep.media.mediaType === "video") {
            return true;
          }
        }
      } else {
        return false;
      }
      return false;
    });
    const textareaRef = (el) => {
      if (el) {
        state.itemRefs.push(el);
      }
    };
    const levelHandleClick = (i) => {
      dataLevel.value.steps.splice(i, 1);
    };
    const StepHandleClick = (i) => {
      dataLevel.value.ingredients.splice(i, 1);
    };
    const addStep = () => {
      dataReciep.value.push(null);
    };
    const addIngr = () => {
      dataIngredients.value.push({ name: null, quantity: null });
    };

    const deleteMainImage = () => {
      dataLevel.value.image = null;
      dataLevel.value.mediaItemId = null;
      state.Image = null;
      dataLevel.value.media = null;
    };
    const addImage = (id, link) => {
      const storage = getStorage();
      const forestRef = storageRef(storage, `media-library/${id}/original`);

      // Get metadata properties
      getMetadata(forestRef)
        .then((metadata) => {
          dataLevel.value.media = {
            mediaItemId: id,
            mediaLink: link,
          };
          let type = metadata.contentType.split("/")[1];
          if (type === "jpeg" || type === "jpg" || type === "png") {
            dataLevel.value.media.mediaType = "image";
          } else if (type === "gif") {
            dataLevel.value.media.mediaType = "gif";
          } else {
            dataLevel.value.media.mediaType = "video";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          if (dataLevel.value.media.mediaType === "video") {
            createToast(
              {
                title: "Failure",
                description: "You can't select a video here",
              },
              {
                type: "danger",
                position: "top-center",
                transition: "bounce",
              }
            );
          } else {
            (dataLevel.value.mediaItemId = id),
              (state.filePath = link),
              (dataLevel.value.image = link),
              (state.Image = link),
              (state.showDialogImage = false);
          }
        });
    };
    const handleClick = () => {
      state.showDialogImage = true;
      state.typeImg = "questionImage";
    };
    const dragFile = async (e) => {
      let data = e.dataTransfer.files[0];
      const compressedFile = await imageCompression(data, {
        maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
        maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
        useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
        // advanced options
        initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
      });
      state.file = compressedFile;
      createImage();
    };
    const uploadFile = async (dataD, file) => {
      state.tags = dataD.tags;
      if (file.type === "image/gif") {
        const selected = file;

        if (selected) {
          state.file = selected;
          state.filePath = URL.createObjectURL(state.file);
        }
      } else {
        let data = file;
        const compressedFile = await imageCompression(data, {
          maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
          maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
          // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
          maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
          useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
          // advanced options
          initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
        });
        state.file = compressedFile;
      }
      createImage();
    };
    const handClick = () => {
      /* AddFile.value.click(); */
      state.showDialogChoseImage = true;
      state.typeChoose = "Add";
    };
    const updateDish = () => {
      state.isLoading = true;
      if (!dataLevel.value.title) {
        createToast(
          {
            title: "Error",
            description: "Title field is empty",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
        state.isLoading = false;
      } else {
        if (props.type === "Update") {
          if (recipeSource.value) {
            dataLevel.value.recipeSource = recipeSource.value;
          }
          db.collection("Recipes")
            .doc(dataLevel.value.id)
            .update(dataLevel.value)
            .then(() => {
              state.isLoading = false;
              createToast(
                {
                  title: "Success",
                  description: "Recipe successfully updated",
                },
                {
                  type: "success",
                  position: "top-center",
                  transition: "bounce",
                }
              );
            })
            .catch((e) => console.log(e));
        } else {
          if (recipeSource.value) {
            dataLevel.value.recipeSource = recipeSource.value;
          }
          db.collection("Recipes")
            .add(dataLevel.value)
            .then((a) => {
              let max = 0;
              console.log(max);
              db.collection("Recipes").doc(a.id).update({ order: max });
              context.emit("create", a.id);
              state.isLoading = false;
              createToast(
                {
                  title: "Success",
                  description: "Recipe successfully added",
                },
                {
                  type: "success",
                  position: "top-center",
                  transition: "bounce",
                }
              );
            });
        }
      }
    };
    const createImage = async () => {
      state.isLoading = true;
      let newImage = {
        type: "image",
        tags: state.tags,
        iconLink: null,
        title: "UploadedImage",
      };
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(state.file, "media-library/" + a.id);
          dataLevel.value.mediaItemId = a.id;
          dataLevel.value.media = {
            mediaItemId: a.id,
          };
          const storage = getStorage();
          const forestRef = storageRef(
            storage,
            `media-library/${a.id}/original`
          );

          // Get metadata properties
          getMetadata(forestRef)
            .then((metadata) => {
              let type = metadata.contentType.split("/")[1];
              if (type === "jpeg" || type === "jpg" || type === "png") {
                dataLevel.value.media.mediaType = "image";
              } else if (type === "gif") {
                dataLevel.value.media.mediaType = "gif";
              }
            })
            .catch((error) => {
              console.log(error);
            });
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .then((info) => {
              state.isLoading = false;
              console.log(info, "infoooo"),
                (state.filePath = url.value),
                (dataLevel.value.image = url.value),
                (state.answerImage = url.value),
                (dataLevel.value.media.mediaLink = url.value);
              state.showDialogChoseImage = false;
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    };
    const deleteDish = () => {
      db.collection("Recipes")
        .doc(dataLevel.value.id)
        .delete()
        .then(() => {
          state.showDialogoConfirm = false;
          context.emit("close");
        });
    };
    onMounted(() => {});

    return {
      ...toRefs(state),
      dataLevel,
      deleteDish,
      recipeSource,
      addStep,
      createImage,
      deleteMainImage,
      handleClick,
      uploadFile,
      dragFile,
      updateDish,
      dataReciep,
      resize,
      checkVideo,
      dataChosenLevels,
      AddFile,
      textareaRef,
      StepHandleClick,
      dataIngredients,
      addImage,
      addIngr,
      handClick,
      levelHandleClick,
      searchTerm,
    };
  },
};
</script>

<style scoped>
.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.selected.highlighted {
  background-color: #f5f5f5 !important;
}
.image {
  height: 100px;
  width: 100px;
}
.fa-plus:before {
  content: "\f067";
  color: #bdbbb7;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-d {
  width: 315px;
}
button:disabled {
  background: transparent;
  color: green;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block {
  background-color: #0e0e0e;
}
</style>
