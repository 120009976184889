<template>
  <div class="w-full p-16">
    <form @submit.prevent>
      <loading
        v-model:active="isLoading"
        :can-cancel="false"
        :on-cancel="false"
        color="#51A045"
        :is-full-page="fullPage"
      />
      <div class="mb-6 flex flex-row">
        <label class="flex mr-4 dark:text-gray-50 text-gray-700 font-serif text-sm font-bold"
          >Title</label
        >
        <input
          v-model="dataLevel.title"
          placeholder="Title"
          class="flex bg-white font-serif mr-auto appearance-none border-b border-black w-64 focus py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
          type="text"
        />
      </div>
      <div class="mb-6 w-full flex flex-row">
        <label
          class="flex mr-4 dark:text-gray-50 text-gray-700 font-serif text-sm font-bold my-auto"
          >Category</label
        >
        <Listbox as="div" class="w-1/3 h-11" v-model="selected">
          <div class="mt-1 relative">
            <ListboxButton
              class="bg-white relative w-full h-10 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <span v-if="selected" class="block truncate">
                {{ selected.charAt(0).toUpperCase() + selected.slice(1) }}</span
              >
              <span v-else class="block truncate text-gray-400">
                Choose Category</span
              >
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <SelectorIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-10 mt-1 w-full bg-mywhite shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-gray-300 overflow-auto focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  v-for="category in categories"
                  :key="category.id"
                  v-slot="{ active, selected }"
                  @click="choseDataCategory(category)"
                >
                  <li
                    class="border-b border-gray-200"
                    :class="[
                      active ? 'text-mywhite bg-mygreen' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                      ]"
                    >
                      {{
                        category.name.charAt(0).toUpperCase() +
                        category.name.slice(1)
                      }}
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-mywhite' : 'text-mygreen',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <!-- 
          <button
            class="text-black w-52 bg-mywhite border-b focus:outline-none focus:bg-white focus:border-none font-medium text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-mywhite dark:hover:bg-mywhite dark:focus:bg-mywhite"
            type="button"
            @click="isDropd = !isDropd"
          >
            {{ dataLevel.category }}
            <svg
              class="w-4 h-4 ml-2 ml-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div class="z-10 absolute w-64">
            <div
              class="bg-mywhite divide-y divide-gray-100 shadow w-44 dark:bg-gray-700"
              v-for="category in categories"
              :key="category"
            >
              <ul
                v-if="isDropd"
                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefault"
              >
                <li>
                  <a
                    @click="choseDataCategory(category)"
                    class="block cursor-pointer px-4 py-2 focus:outline-none focus:bg-white focus:border-none"
                    >{{ category.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
      <div class="mb-6 w-64">
        <label for="toggleA" class="flex dark:text-gray-50 items-center cursor-pointer">
          <div class="mr-8 w-64 text-left dark:text-gray-50 font-bold text-gray-700 font-medium">
            Is Live?
          </div>
          <!-- toggle -->
          <div class="relative">
            <!-- input -->
            <input
              type="checkbox"
              id="toggleA"
              class="sr-only"
              v-model="dataLevel.isLive"
            />
            <!-- line -->
            <div class="block bg-gray-200 w-14 h-8 rounded-full"></div>
            <!-- dot -->
            <div
              class="dot absolute left-1 top-1 bg-mywhite w-6 h-6 rounded-full transition"
            ></div>
          </div>
          <!-- label -->
        </label>
      </div>
      <div class="mb-6" v-if="dataType == 'Add'">
        <button
          class="w-32 max-w-16 mr-auto px-6 h-9 font-sans px-6 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
          @click="createLevel"
        >
          <div
            v-if="loading"
            class="animate-spin rounded-full h-5 w-5 border-b-2 border-mywhite m-auto"
          ></div>
          <span v-else>Add</span>
        </button>
      </div>
      <div class="mb-6 flex flex-row" v-if="dataType == 'Update'">
        <button
          v-if="!dataLevel.archive"
          class="font-sans h-9 w-32 max-w-16 px-6 mr-4 font-bold font-sans text-red-400 transition-colors duration-150 rounded-full border border-red-400 focus:border-current"
          @click="deleteLevel"
        >
          Archive
        </button>
        <button
          v-if="dataLevel.archive"
          class="font-sans h-9 w-32 max-w-16 px-6 mr-4 font-bold font-sans text-yellow-400 transition-colors duration-150 rounded-full border border-yellow-400 focus:border-current"
          @click="restoreLevel"
        >
          Restore
        </button>
        <button
          class="w-32 font-sans h-9 px-6 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
          @click="updateLevel"
        >
          <span>{{ dataType }}</span>
        </button>
        <button
          class="w-40 ml-4 font-sans h-9 px-6 font-bold font-sans text-mywhite transition-colors duration-150 bg-blue-800 rounded-full focus:border-current"
          @click="openDialogAddToDish"
        >
          <span>Add to Dish</span>
        </button>
      </div>
    </form>
    <my-dialog v-model:show="showDialogImage">
      <chose-level-image-popup @add="addImage"> </chose-level-image-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogAddToDish" :title="'Add to dish'">
      <add-to-dish
        :indexImage="indexSnipy"
        :questionId="dataLevel.id"
        @add="moveToFunc"
      >
      </add-to-dish>
    </my-dialog>
  </div>
</template>

<script>
/* import { createToast } from "mosha-vue-toastify"; */
import MyDialog from "@/components/MyDialog";
import AddToDish from "./addToDish.vue";
import ChoseLevelImagePopup from "./choseLevelImagePopup.vue";
import { toRefs, ref, reactive, computed } from "vue";
import getCollectionOrder from "@/composables/getCollectionUnitsOrder";
import { db } from "@/firebase/config";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { createToast } from "mosha-vue-toastify";

export default {
  name: "level-form",
  props: {
    type: String,
    unitList: Object,
    level: Object,
  },
  components: {
    Listbox,
    AddToDish,
    ListboxButton,
    Loading,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    ChoseLevelImagePopup,
    MyDialog,
  },
  setup(props, context) {
    const state = reactive({
      showDialogImage: false,
      category: null,
      showDialogAddToDish: false,
      isDropd: false,
      categories: [
        { id: 1, name: "shopping" },
        { id: 2, name: "ingredients" },
        { id: 3, name: "equipment" },
        { id: 4, name: "techniques" },
        { id: 5, name: "prepping" },
        { id: 6, name: "making" },
      ],
    });
    const selected = computed(() => {
      return dataLevel.value.category;
    });
    const { documents: dishes } = getCollectionOrder("Dish");
    let isLoading = ref(false);
    const dataLevel = computed(() => {
      return props.level;
    });
    const dataType = computed(() => {
      return props.type;
    });
    /*     const checkDishOnLevel = () => {
     console.log(dishes.value.filter((a) =>  a.levels.find((b) => b === dataLevel.value.id)))
      
    }; */
    const deleteImageLevel = () => {
      state.levelForm.iconName = null;
      this.imageName = null;
    };
    const openDialogAddToDish = () => {
      state.showDialogAddToDish = true;
    };
    const moveToFunc = () => {
      state.showDialogAddToDish = false;
    };
    const deleteLevel = () => {
      dataLevel.value.isLive = false;
      dataLevel.value.archive = true;
      db.collection("LevelContent")
        .doc(dataLevel.value.id)
        .update(dataLevel.value);
      context.emit("close");
    };
    const restoreLevel = () => {
      dataLevel.value.isLive = false;
      dataLevel.value.archive = false;
      db.collection("LevelContent")
        .doc(dataLevel.value.id)
        .update(dataLevel.value);
      context.emit("close");
    };
    const createLevel = () => {
      if (dataLevel.value.title) {
        isLoading.value = true;
        db.collection("LevelContent")
          .add(dataLevel.value)
          .then((a) => {
            createToast(
              {
                title: "Success",
                description: "Level successfully added",
              },
              {
                type: "success",
                position: "top-center",
                transition: "bounce",
              }
            );
            (dataLevel.value.title = null),
              (dataLevel.value.category = null),
              context.emit("create", a.id),
              (isLoading.value = false);
          });
      } else {
        createToast(
          {
            title: "Incorect data",
            description: "there are empty field",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      }
    };
    const updateLevel = () => {
      if (dataLevel.value.title) {
        db.collection("LevelContent")
          .doc(dataLevel.value.id)
          .update(dataLevel.value)
          .then(() => {
            createToast(
              {
                title: "Success",
                description: "Level successfully updated",
              },
              {
                type: "success",
                position: "top-center",
                transition: "bounce",
              }
            );
          });
      } else {
        createToast(
          {
            title: "Incorect data",
            description: "there are empty field",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      }
    };
    const choseDataCategory = (a) => {
      console.log(a);
      dataLevel.value.category = a.name;
      state.isDropd = false;
    };

    return {
      ...toRefs(state),
      dataLevel,
      updateLevel,
      dishes,
      moveToFunc,
      openDialogAddToDish,
      choseDataCategory,
      deleteLevel,
      restoreLevel,
      isLoading,
      selected,
      deleteImageLevel,
      createLevel,
      dataType,
    };
  },
  /* data() {
    return {
      unitlist: this.unitList,
      levelForm: {
        title: "",
        isLive: false,
        iconName: null,
      },
      levelFormToMove: {
        title: "",
        isLive: false,
        levelContentId: "",
        id: "",
        iconName: null,
      },
      file: null,
      showDialogImage: false,
      filePath: null,
      errors: [],
      typeImg: "",
      typeChange: "",
      loading: false,
      unitId: null,
      imageName: null,
    };
  },

  methods: {
    deleteImageLevel(){
      this.levelForm.iconName = null;
      this.imageName = null;
    },
    addImage(src) {
      this.levelForm.iconName = src;
      this.imageName = `/levelImages/${src}.png`;
      this.showDialogImage = false;
    },


    addHandleClick() {
      this.loading = true;
      this.levelFormToMove.levelContentId = this.unitList.levelContentId;
      this.levelFormToMove.id = this.unitList.id;
      if (!this.levelForm.title) {
        this.errors.push("Enter Title");
      }

      if (!this.levelForm.subTitle) {
        this.errors.push("Enter Level Title");
      }

      if (this.errors.length) {
        createToast(
          {
            title: "Incorect data ",
            description: this.errors[0],
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      } else {
        this.$emit("create", this.levelForm, this.$props.level.unitId);
      }
      this.errors = [];
    },
    updateHandleClick() {
      this.loading = true;
      if (!this.levelForm.title) {
        this.errors.push("Enter Title");
      }

      if (this.errors.length) {
        createToast(
          {
            title: "Incorect data ",
            description: this.errors[0],
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      } else {
        this.$emit(
          "update",
          this.levelForm,
          this.$props.level.id
        );
      }
      this.errors = [];
    },
    deleteHandleClick() {
      this.$emit("delete", this.$props.level.id, this.$props.level.unitId);
    },

    handleClick() {
      this.showDialogImage = true;
      this.typeImg = "LevelImage";
    },
  },
  async mounted() {
    this.typeChange = this.$props.type;
    document.body.addEventListener(
      "keydown",
      (e) => {
        if (e.keyCode === 27) {
          this.$emit("close");
        }
      },
      { once: true }
    );
    if (this.$props.type === "Update") {
      this.levelForm.isLive = this.$props.level.isLive ? this.$props.level.isLive: false;
      this.levelForm.title = this.$props.level.title;
      this.unitId = this.$props.level.unitId;
      if(this.$props.level.iconName){
      this.imageName = `/levelImages/${this.$props.level.iconName}.png`;
      this.levelForm.iconName = this.$props.level.iconName;
      } else {
        this.imageName = null;
        this.levelForm.iconName = null;
      }
    }
  }, */
};
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.highlighted {
  background-color: #f5f5f5 !important;
}
.vue-dropdown-item.selected.highlighted {
  background-color: #f5f5f5 !important;
}
.image {
  height: 100px;
  width: 100px;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-d {
  width: 315px;
}
button:disabled {
  background: transparent;
  color: green;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block {
  background-color: #0e0e0e;
}
</style>
