<template>
  <div class="w-full p-2 bg-gray-100 scrollbar-hide dark:bg-bggraym dark:text-gray-50 min-h-screen md:p-6 lg:p-16 screen-media">
    <div
      class="w-full bg-mywhite dark:bg-bggrayl dark:text-gray-50 min-h-screen flex flex-row rounded-3xl lg:p-3 screen-media"
    >
      <table class="table-fixed w-100% scrollbar-hide text-left">
        <thead>
          <tr>
            <th>Recipes</th>
          </tr>
        </thead>
        <tbody>
          <tr class="scrollbar-hide">
            <td
              class="align-top level scrollbar-hide border-r border-gray-200 max-h-screen overflow-scroll"
              style="height: 800px"
            >
              <div class="flex scrollbar-hide flex-col w-72 max-h-screen overflow-scroll p-2">
                <button
                  @click="openDialogAddLevel"
                  class="mt-5 btn-add mr-auto"
                >
                  + Add Reciep
                </button>
                <input
                  v-model="searchQuery"
                  class="mt-2 pl-2 border rounded-lg float-right mb-2"
                  placeholder="Search"
                />
                <draggable
                  class="dragArea list-group w-full"
                  :list="searchedProducts"
                  @dragend="updDihs"
                >
                  <div
                    @click="levelHandleClick(recip)"
                    class="list-group-item flex flex-row p-3 font-bold border border-gray-200 rounded-md mb-1"
                    v-for="recip in searchedProducts"
                    :key="recip.id"
                    :class="{ 'active-li': recipeActive === recip.id }"
                  >
                    <div class="flex flex-row pt-3 pr-3"></div>
                    <div class="flex-col justify-center w-full">
                      <p>{{ recip.title }}</p>
                      <p class="font-normal">{{ recip.subTitle }}</p>
                    </div>

                    <div class="flex flex-row">
                      <div
                        class="edit ml-3"
                        @click="openDialogUpdateLevel(recip)"
                      ></div>
                      <div
                        v-if="recipeActive === recip.id"
                        class="icon ml-3 max-h-8"
                      ></div>
                    </div>
                  </div>
                </draggable>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="flex w-60 h-9 my-72 mx-auto"
        v-if="!recipeActive && !showDialogLevel"
      >
        <p class="text-lg font-sans text-gray-400 text-center">
          Please select recipe to see detatils
        </p>
      </div>
      <dish-form
        v-show="showDialogLevel === true"
        :reciep="recipeForm"
        :type="type"
        :levels="searchedProducts"
        @close="closeContent"
        @create="createDish"
      ></dish-form>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
/* import { ref, computed, onMounted } from "vue";
import firebase from "firebase/compat";
import { useRouter } from "vue-router"; */
import { DateTime } from "luxon";
import { ref, computed } from "vue";
import dishForm from "./reciepsLevelpopup.vue";
import getCollectionUnit from "@/composables/getCollectionUnitsOrder";
import firebase from "firebase/compat";

export default {
  components: {
    draggable: VueDraggableNext,
    dishForm,
  },
  setup() {
    const { documents: recipe } = getCollectionUnit("Recipes");
    const showDialogLevel = ref(false);
    const recipeActive = ref(null); //id level
    const recipeData = ref([]);
    const searchQuery = ref("");
    const recipeForm = ref({
      createdAt: DateTime.now().toMillis(),
      image: null,
      ingredients: [{ name: null, quantity: null }],
      servings: null,
      steps: [""],
      time: null,
    });
    const type = ref(""); //id level
    const hideDialog = () => {
      showDialogLevel.value = false;
    };
    const updDihs = () => {
      searchedProducts.value.forEach(async (element, index) => {
        console.log(element.title, index);
        element.order = index;
        firebase
          .firestore()
          .collection("Recipes")
          .doc(element.id)
          .update({ order: index })
          .catch((error) => {
            console.log(error);
          });
      });
    };
    const searchedProducts = computed(() => {
      if (recipe.value) {
        return recipe.value.filter((product) => {
          return (
            product.title
              .toLowerCase()
              .indexOf(searchQuery.value.toLowerCase()) != -1
          );
        });
      } else {
        return recipe.value;
      }
    });
    const createDish = () => {
      searchedProducts.value.forEach(async (element, index) => {
        element.order = index;
        firebase
          .firestore()
          .collection("Recipes")
          .doc(element.id)
          .update({ order: index + 1 })
          .catch((error) => {
            console.log(error);
          });
      });
      hideDialog();
      /* .then(() => {
         db.collection("LevelContent")
        .add({
        levelId: levelActive.value,
        unitId: unitActive.value,
        createdAt: DateTime.now().toMillis(),
        levelQuestions: [],
        })
      }); */
    };
    const levelHandleClick = (level) => {
      recipeData.value = [];
      recipeActive.value = level.id;
      showDialogLevel.value = true;
      type.value = "Update";
      recipeForm.value = level;
    };

    const openDialogAddLevel = () => {
      recipeActive.value = null;
      type.value = "Add";
      showDialogLevel.value = true;
      recipeForm.value = {
        createdAt: DateTime.now().toMillis(),
        image: null,
        ingredients: [{ name: null, quantity: null }],
        servings: null,
        steps: [""],
        time: null,
      };
    };
    const closeContent = () => {
      hideDialog();
    };
    return {
      recipe,
      updDihs,
      showDialogLevel,
      levelHandleClick,
      recipeForm,
      recipeData,
      openDialogAddLevel,
      hideDialog,
      type,
      searchedProducts,
      searchQuery,
      closeContent,
      recipeActive,
      createDish,
    };
  },
};
</script>

<style scoped>
table {
  padding: 40px;
}
.align-top {
  max-width: 300px;
}
@media screen and (max-width: 600px) {
  .screen-media {
    min-height: 2100px;
  }
}
.nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
.active-li {
  border: 1px solid #eda81c;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.icon {
  border-radius: 50%;
  background: #eda81c;
  font-size: 14px;
}

.edit {
  display: none;
  cursor: pointer;
}

.active-li:hover .edit {
  display: block;
}

@media screen and (max-width: 500px) {
  .span {
    display: none;
  }
}

.sortable-ghost {
  visibility: hidden;
}

.btn-add {
  font-size: 14px;
  color: #51a045;
}
</style>
