<template>
  <div
    class="flex flex-col items-start justify-center w-64  p-8 rounded-lg"
  >
  <p class="font-sans mb-4">Level Category</p>
    <check-box
      v-for="option in options"
      :checked="value.includes(option.name)"
      @update:checked="check(option.name, $event)"
      :fieldId="option.name"
      :label="option.name"
      :key="option"
    />
    <button 
     class="font-sans mt-7 h-9 w-28 max-w-16 px-6 font-bold font-sans text-mygreen transition-colors duration-150 rounded-full border border-mygreen focus:border-current"
    @click="clearData">Clear</button>
  </div>
</template>

<script>
import Checkbox from "./checkbox.vue";

export default {
  emits: ["update:value","clear"],
  props: {
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
          Object.keys(option).includes("name")
        );
        const hasIdKey = value.every((option) =>
          Object.keys(option).includes("id")
        );
        return hasNameKey && hasIdKey;
      },
    },
  },
  setup(props, context) {
    const clearData = () =>{
       context.emit("clear");
    }
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit("update:value", updatedValue);
    };

    return {
      check,
      clearData
    };
  },
  components: {
    "check-box": Checkbox,
  },
};
</script>
