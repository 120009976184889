<template>
  <div
    class="w-2/3 px-1 py-4 bg-white min-h-screen dark:text-gray-50"
  >
    <!--  Choosing type of question -->
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :on-cancel="false"
      color="#51A045"
      :is-full-page="fullPage"
    />
    <div
      class="flex flex-col w-full px-3 dark:text-gray-50"
      v-if="!question.type && !question.id"
    >
      <div class="personal flex flex-row w-full dark:text-gray-50">
        <div class="m-auto grid grid-cols-2 space-x-4 pl-5">
          <button
            @click="question.type = 'knowledge_question'"
            class="h-28 w-64 border-2 mb-3 ml-4 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/text_question.png"
                class="max-h-11 mx-2 mt-7"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif text-gray-700 dark:text-gray-50 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Text
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              >
                Picture or text question and text options for answer
              </p>
            </div>
          </button>
          <button
            @click="question.type = 'picture_answer'"
            class="h-28 w-64 border-2 mb-3 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/image_question.png"
                class="max-h-11 mx-2 mt-7"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif dark:text-gray-50 text-gray-700 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Image
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              >
                Image or text question and images option for answer
              </p>
            </div>
          </button>
          <button
            @click="question.type = 'ordering_question'"
            class="h-28 mb-4 w-64 border-2 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/order_question.png"
                class="max-h-11 mx-2 mt-7 p-1"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif dark:text-gray-50 text-gray-700 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Sequencing question
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              >
                Questions with ordering
              </p>
            </div>
          </button>
          <button
            @click="question.type = 'multiselect_questions'"
            class="h-28 w-64 border-2 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/order_question.png"
                class="max-h-11 mx-2 mt-7 p-1"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif dark:text-gray-50 text-gray-700 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Multiselect Questions
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              ></p>
            </div>
          </button>
          <button
            @click="question.type = 'matching_questions'"
            class="h-28 w-64 border-2 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/order_question.png"
                class="max-h-11 mx-2 mt-7 p-1"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif dark:text-gray-50 text-gray-700 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Matching Questions
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              ></p>
            </div>
          </button>
          <button
            @click="question.type = 'fast_question'"
            class="h-28 w-64 border-2 border-mygreen rounded-lg flex flex-row"
          >
            <div>
              <img
                src="@/assets/order_question.png"
                class="max-h-11 mx-2 mt-7 p-1"
              />
            </div>
            <div class="flex flex-col max-h-5 pl-5">
              <p
                class="flex font-serif dark:text-gray-50 text-gray-700 text-sm font-bold max-h-20 mt-3 text-left"
              >
                Fast Questions
              </p>
              <p
                class="flex font-serif text-gray-400 text-xs font-normal max-h-5 text-left"
              ></p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="w-full flex flex-col">
        <div class="flex w-full flex-col">
          <div
            class="w-full md:w-full px-3 mb-6 flex flex-row max-h-40"
            style="min-width: 300px"
          >
            <div>
              <button
                class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
                @click="deleteMainImage"
              >
                <p class="m-auto">✕</p>
              </button>
              <label
                class="flex font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold"
                >Image</label
              >
              <div class="flex justify-center mt-2 bg-gray-100 rounded-lg h-36">
                <div
                  class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                  :style="{
                    background:
                      'left/100% no-repeat url(' + question.image + ')',
                  }"
                >
                  <video
                    v-if="checkVideo"
                    controls
                    ref="videoPlayer"
                    id="myVideoPlayer"
                    class="video-js videoMain w-47"
                  >
                    <source :src="question.image" type="video/mp4" />
                  </video>
                  <div>
                    <div v-if="!question.image" @dragover.prevent @drop.prevent>
                      <img
                        v-if="question.type != 'fast_question'"
                        @click="handleClick"
                        class="m-auto mb-7 mt-5 max-h-20 w-10 max-h-52"
                        ref="img"
                        :src="require('@/assets/form-image1.png')"
                      />
                      <img
                        v-if="question.type === 'fast_question'"
                        class="m-auto mb-7 mt-14 max-h-20 w-10 max-h-52"
                        ref="img"
                        :src="require('@/assets/notav.png')"
                      />
                      <!--  <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        ref="AddFile"
                        style="visibility: hidden"
                        class="bg-transparent"
                        multiple
                        @change="uploadFile"
                      /> -->
                      <img
                        v-if="question.type != 'fast_question'"
                        @click="handClick"
                        class="m-auto mt-2 max-h-20 w-10 max-h-52"
                        ref="img"
                        :src="require('@/assets/uploadImage.png')"
                      />
                    </div>
                    <img v-else class="m-auto w-2xl h-48" ref="img" />
                    <input
                      ref="image"
                      style="visibility: hidden"
                      @change="hanldeChange"
                      type="file"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-row pl-2">
                <div class="w-1/3">
                  <label
                    class="flex font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold mb-1"
                    >Randomize</label
                  >
                  <div class="w-7 text-left">
                    <Toggle v-model="question.randomize" class="toggle-red" />
                  </div>
                </div>
                <div class="w-1/3">
                  <label
                    class="flex font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold mb-1"
                    >isLive?</label
                  >
                  <div class="w-7 text-left">
                    <Toggle v-model="question.isLive" class="toggle-red" />
                  </div>
                </div>
                <div class="w-1/3" v-if="question.type === 'fast_question'">
                  <label
                    class="flex font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold mb-1"
                    >Is image?</label
                  >
                  <div class="w-7 text-left">
                    <Toggle v-model="isImage" class="toggle-red" />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col">
              <div>
                <label
                  class="flex font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold mb-2"
                  >Question</label
                >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <textarea
                    @mouseover="whenClickInpute = true"
                    @mouseleave="whenClickInpute = false"
                    v-model="question.question"
                    class="form-control block focus:outline-none focus:bg-white focus:border-none w-full px-3 py-1.5 text-base font-normal  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Your message"
                    :class="[
                      'w-full',
                      treckField && !question.question && question.isLive
                        ? 'border-red-500 border text-red-500'
                        : 'bg-mywhite',
                    ]"
                  ></textarea>
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                  >
                    <ExclamationCircleIcon
                      v-if="treckField && !question.question && question.isLive"
                      class="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>

              <div class="mt-2 flex flex-row">
                <div class="w-full flex flex-row">
                  <button
                    class="w-24 ml-1 bottom-3 font-sans h-12 font-bold font-sans text-mywhite mt-4 transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
                    @click="createQuest()"
                    v-shortkey.once="['ctrl', 'enter']"
                    @shortkey="createQuest()"
                    :class="{ 'active-li': addSide === true }"
                  >
                    <span class="pb-3">{{ type }}</span>
                  </button>
                  <button
                    v-if="type === 'Update'"
                    class="w-24 ml-3 mt-4 ont-sans h-12 font-bold font-sans text-mywhite mt-4 transition-colors duration-150 bg-blue-400 rounded-full focus:border-current"
                    @click="cloneHandleClick"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Clone
                  </button>
                  <button
                    v-if="type === 'Update'"
                    class="w-24 ml-3 mt-4 ont-sans h-12 font-bold font-sans text-mywhite mt-4 transition-colors duration-150 bg-blue-800 rounded-full focus:border-current"
                    @click="openDialogMoveTo(question.id)"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Move to
                  </button>
                  <button
                    v-if="type === 'Update'"
                    class="w-12 ml-3 mt-4 ont-sans h-12 font-bold font-sans text-mywhite mt-4 transition-colors duration-150 bg-red-600 rounded-lg focus:border-current"
                    @click="deleteHandleClick"
                    :class="{ 'active-li': addSide === true }"
                  >
                    <TrashIcon
                      class="h-7 w-7 text-mywhite m-auto"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-2/3 flex flex-col mt-3 ml-2"
          v-if="
            question.type === 'ordering_question' ||
            question.type === 'matching_questions' ||
            question.type === 'multiselect_questions'
          "
        >
          <!--  <div class="flex flex-col w-full mt-8">
            <div class="flex flex-row">
              <label
                class="flex w-full font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                >explain Title</label
              >
              <div class="w-full flex flex-col">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    v-model="question.explainTitle"
                    class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                    placeholder="Your message"
                    :class="[
                      'w-full',
                      treckField &&
                      !question.explainTitle &&
                      question.isLive &&
                      question.type === 'ordering_question'
                        ? 'border-red-500 border-b text-red-500'
                        : 'bg-mywhite',
                    ]"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                  >
                    <ExclamationCircleIcon
                      v-if="
                        !question.explainTitle &&
                        treckField &&
                        question.isLive &&
                        question.type === 'ordering_question'
                      "
                      class="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex flex-row pr-3">
                <label
                  class="flex w-2/3 mt-2 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                  >Explain Description</label
                >
                <p
                  v-if="question.explainDescription"
                  class="w-1/3 mt-2 text-right"
                >
                  {{ question.explainDescription.length }}
                </p>
              </div>
              <div class="mt-1 relative rounded-md shadow-sm">
                <textarea
                  v-model="question.explainDescription"
                  class="form-control block focus:outline-none focus:bg-white focus:border-none w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Your message"
                  :class="[
                    'w-full',
                    treckField &&
                    !question.explainDescription &&
                    question.isLive &&
                    question.type === 'ordering_question'
                      ? 'border-red-500 border-b text-red-500'
                      : 'bg-mywhite',
                  ]"
                ></textarea>
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <ExclamationCircleIcon
                    v-if="
                      treckField &&
                      !question.explainDescription &&
                      question.isLive &&
                      question.type === 'ordering_question'
                    "
                    class="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <div class="flex flex-row mt-9 ml-2">
            <label
              class="flex m-14 mx-6 font-serif dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold m-2"
              >Explain Image
              <ExclamationCircleIcon
                v-if="
                  !question.imageName &&
                  treckField &&
                  question.type === 'multiselect_questions'
                "
                class="h-5 w-5 text-red-500"
                aria-hidden="true"
            /></label>
            <div>
              <div
                class="flex justify-center mt-2 bg-gray-100 rounded-lg h-32 w-32"
              >
                <div
                  @click="showDialogSnippyCorrect(question.type)"
                  class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                >
                  <div>
                    <img
                      v-if="question.imageName"
                      class="m-auto rounded-lg h-32 w-32 max-h-52"
                      :src="require(`@/assets/${question.imageName}.jpeg`)"
                    />
                    <img
                      v-if="!question.imageName"
                      class="m-auto mt-12 h-8 w-9 max-h-52"
                      ref="img"
                      :src="require('@/assets/form-image1.png')"
                    />
                    <input
                      ref="image"
                      style="visibility: hidden"
                      @change="hanldeChange"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="question.type === 'ordering_question'"
            class="flex flex-row w-full space-x-9"
          >
            <div class="flex flex-col w-1/2">
              <label
                class="flex w-full font-serif tracking-wide dark:text-gray-50 text-gray-700 text-base font-bold mt-2"
                >Top Title</label
              >
              <div class="w-full flex flex-col">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    v-model="question.topTitle"
                    class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                    placeholder="Your message"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/2">
              <label
                class="flex w-full font-serif tracking-wide dark:text-gray-50  text-gray-700 text-base font-bold mt-2"
                >Bottom Title</label
              >
              <div class="w-full flex flex-col">
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    v-model="question.bottomTitle"
                    class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                    placeholder="Your message"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <draggable2
          class="dragArea list-group w-full pt-3 mt-5"
          :list="answersArr"
          filter=".ignore"
          :disabled="whenClickInpute"
        >
          <!--  THIS FAST QYESTIONS -->
          <div
            class="item list-group-item flex flex-row bg-black-500 p-2 mt-1 font-bold"
            style="text-align: left"
            v-for="(q, index) in answersArr"
            :key="q.id"
          >
            <div
              v-if="question.type === 'fast_question'"
              class="item w-full border p-5"
            >
              <div class="item flex flex-row w-full">
                <div class="item w-full flex flex-col" v-if="!isImage">
                  <label
                    class="item dark:text-gray-50 flex w-24 mr-3 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Title</label
                  >
                  <div class="w-full flex flex-col">
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input
                        @mouseover="whenClickInpute = true"
                        @mouseleave="whenClickInpute = false"
                        v-model="q.title"
                        class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                        placeholder="Your message"
                        :class="[
                          'w-full',
                          treckField && !q.title && question.isLive
                            ? 'border-red-500 border-b text-red-500'
                            : 'bg-mywhite',
                        ]"
                      />
                      <div
                        class="item absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <ExclamationCircleIcon
                          v-if="!q.title && treckField && question.isLive"
                          class="item h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item ml-3" v-if="isImage">
                  <button
                    class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
                    @click="deleteAnswImage(index)"
                  >
                    <p class="m-auto">✕</p>
                  </button>
                  <label
                    class="flex dark:text-gray-50 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Image</label
                  >
                  <div
                    class="flex justify-center mt-2 bg-gray-100 rounded-lg h-32 w-32"
                  >
                    <div
                      class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                      :style="{
                        background:
                          'left/100% no-repeat url(' + q.answerImage + ')',
                      }"
                    >
                      <div>
                        <div
                          class="flex flex-col"
                          @dragover.prevent
                          @drop.prevent
                        >
                          <img
                            @click="handleClickAnswImg(index)"
                            v-if="!q.answerImage"
                            class="m-auto mt-5 mb-4 h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/form-image1.png')"
                          />
                          <!--  <input
                            type="file"
                            ref="imageAnsw"
                            accept="image/png, image/gif, image/jpeg"
                            style="visibility: hidden"
                            class="bg-transparent"
                            multiple
                            @change="uploadFileAnswer"
                          /> -->
                          <img
                            @click="handClickAnswer(index)"
                            v-if="!q.answerImage"
                            class="m-auto h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/uploadImage.png')"
                          />
                          <input
                            ref="image"
                            style="visibility: hidden"
                            @change="hanldeChange"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-41 ml-auto">
                  <button
                    class="w-24 float-right ont-sans h-9 font-bold font-sans text-mywhite mt-1 transition-colors duration-150 bg-red-600 rounded-full focus:border-current"
                    @click="deleteElement(index)"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="mt-4 w-32 flex flex-row">
                <label
                  class="flex dark:text-gray-50 ml-1 mr-4 w-24 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                  >Correct?</label
                >
                <Toggle v-model="q.isCorrect" class="toggle-red" />
              </div>
            </div>
            <!--  THIS MATCHING QUESTIONS -->
            <div
              v-if="question.type === 'matching_questions'"
              class="item w-full border p-5"
            >
              <div class="item flex flex-row w-full">
                <div class="item w-full flex flex-col">
                  <label
                    class="item  dark:text-gray-50 flex w-24 mr-3 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Title</label
                  >
                  <div class="w-full flex flex-col">
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input
                        @mouseover="whenClickInpute = true"
                        @mouseleave="whenClickInpute = false"
                        v-model="q.title"
                        class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                        placeholder="Your message"
                        :class="[
                          'w-full',
                          treckField && !q.title && question.isLive
                            ? 'border-red-500 border-b text-red-500'
                            : 'bg-mywhite',
                        ]"
                      />
                      <div
                        class="item absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <ExclamationCircleIcon
                          v-if="!q.title && treckField && question.isLive"
                          class="item h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item ml-3">
                  <button
                    class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
                    @click="deleteAnswImage(index)"
                  >
                    <p class="m-auto">✕</p>
                  </button>
                  <label
                    class="flex dark:text-gray-50 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Image</label
                  >
                  <div
                    class="flex justify-center mt-2 bg-gray-100 rounded-lg h-32 w-32"
                  >
                    <div
                      class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                      :style="{
                        background:
                          'left/100% no-repeat url(' + q.answerImage + ')',
                      }"
                    >
                      <div>
                        <div
                          class="flex flex-col"
                          @dragover.prevent
                          @drop.prevent
                        >
                          <img
                            @click="handleClickAnswImg(index)"
                            v-if="!q.answerImage"
                            class="m-auto mt-5 mb-4 h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/form-image1.png')"
                          />
                          <!--  <input
                            type="file"
                            ref="imageAnsw"
                            accept="image/png, image/gif, image/jpeg"
                            style="visibility: hidden"
                            class="bg-transparent"
                            multiple
                            @change="uploadFileAnswer"
                          /> -->
                          <img
                            @click="handClickAnswer(index)"
                            v-if="!q.answerImage"
                            class="m-auto h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/uploadImage.png')"
                          />
                          <input
                            ref="image"
                            style="visibility: hidden"
                            @change="hanldeChange"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-41">
                  <button
                    class="w-24 float-right ml-3 ont-sans h-9 font-bold font-sans text-mywhite mt-1 transition-colors duration-150 bg-red-600 rounded-full focus:border-current"
                    @click="deleteElement(index)"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="
                question.type === 'ordering_question' ||
                question.type === 'multiselect_questions'
              "
              class="w-full border p-5"
            >
              <div class="flex flex-row w-full">
                <div class="w-full flex flex-row">
                  <label
                    class="flex w-24 dark:text-gray-50 mr-3 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Title</label
                  >
                  <div class="w-full flex flex-col">
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input
                        @mouseover="whenClickInpute = true"
                        @mouseleave="whenClickInpute = false"
                        v-model="q.title"
                        class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                        placeholder="Your message"
                        :class="[
                          'w-full',
                          treckField && !q.title && question.isLive
                            ? 'border-red-500 border-b text-red-500'
                            : 'bg-mywhite',
                        ]"
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <ExclamationCircleIcon
                          v-if="!q.title && treckField && question.isLive"
                          class="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="w-41 flex flex-row mt-3"
                  v-if="question.type === 'multiselect_questions'"
                >
                  <label
                    class="flex dark:text-gray-50 ml-3 w-24 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Correct?</label
                  >
                  <Toggle v-model="q.isCorrect" class="toggle-red" />
                </div>
                <div class="w-41">
                  <button
                    class="w-24 float-right ml-3 ont-sans h-9 font-bold font-sans text-mywhite mt-1 transition-colors duration-150 bg-red-600 rounded-full focus:border-current"
                    @click="deleteElement(index)"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row bg-black-500 p-2 border w-full mt-1 font-bold"
              v-if="
                question.type === 'picture_answer' ||
                question.type === 'knowledge_question'
              "
            >
              <div class="flex-col justify-center w-full">
                <div
                  class="flex flex-row w-72 ml-3 mb-2"
                  v-if="question.type === 'picture_answer'"
                >
                  <div class="mt-4 w-32 flex flex-row">
                    <label
                      class="dark:text-gray-50 flex ml-1 mr-4 w-24 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                      >Correct?</label
                    >
                    <Toggle v-model="q.isCorrect" class="toggle-red" />
                  </div>
                  <div>
                    <button
                      class="w-24 ml-5 mt-2 ont-sans h-9 font-bold font-sans text-mywhite transition-colors duration-150 bg-red-600 rounded-full focus:border-current"
                      @click="deleteElement(index)"
                      :class="{ 'active-li': addSide === true }"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div
                  class="flex flex-row"
                  v-if="question.type === 'knowledge_question'"
                >
                  <div class="w-full flex flex-row">
                    <label
                      class="flex dark:text-gray-50 w-1/3 font-serif my-auto tracking-wide text-gray-700 text-base font-bold mb-2"
                      >Answer</label
                    >
                    <div class="w-full flex flex-col">
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          @mouseover="whenClickInpute = true"
                          @mouseleave="whenClickInpute = false"
                          v-model="q.title"
                          class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                          placeholder="Your message"
                          :class="[
                            'w-full',
                            treckField && !q.title && question.isLive
                              ? 'border-red-500 border-b text-red-500'
                              : 'bg-mywhite',
                          ]"
                        />
                        <div
                          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        >
                          <ExclamationCircleIcon
                            v-if="!q.title && treckField && question.isLive"
                            class="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-full flex flex-row">
                    <label
                      class="flex dark:text-gray-50 w-1/3 font-serif my-auto tracking-wide text-gray-700 text-base font-bold mb-2"
                      >explain Title</label
                    >
                    <div class="w-full flex flex-col">
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          @mouseover="whenClickInpute = true"
                          @mouseleave="whenClickInpute = false"
                          v-model="q.explainTitle"
                          class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300"
                          placeholder="Your message"
                          :class="[
                            'w-full',
                            treckField && !q.explainTitle && question.isLive
                              ? 'border-red-500 border-b text-red-500'
                              : 'bg-mywhite',
                          ]"
                        />
                        <div
                          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        >
                          <ExclamationCircleIcon
                            v-if="
                              !q.explainTitle && treckField && question.isLive
                            "
                            class="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex flex-row mt-2">
                    <label
                      class="flex dark:text-gray-50 w-full font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                      >Explain Description</label
                    >
                    <p v-if="q.explainDescription" class="w-full text-right">
                      {{ q.explainDescription.length }}
                    </p>
                  </div>
                  <textarea
                    @mouseover="whenClickInpute = true"
                    @mouseleave="whenClickInpute = false"
                    v-model="q.explainDescription"
                    class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-b border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Your message"
                  />
                </div>
              </div>
              <div
                class="flex flex-col w-72 ml-3"
                v-if="question.type != 'picture_answer'"
              >
                <div class="mt-4 w-full">
                  <label
                    class="flex ml-3 dark:text-gray-50 w-24 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Correct?</label
                  >
                  <Toggle v-model="q.isCorrect" class="toggle-red" />
                </div>
                <div>
                  <button
                    class="w-24 ml-3 mt-4 ont-sans h-9 font-bold font-sans text-mywhite mt-4 transition-colors duration-150 bg-red-600 rounded-full focus:border-current"
                    @click="deleteElement(index)"
                    :class="{ 'active-li': addSide === true }"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="flex flex-row mx-4">
                <div>
                  <button
                    class="bg-mygray ml-1 w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
                    @click="deleteAnswExpImage(index)"
                  >
                    <p class="m-auto">✕</p>
                  </button>
                  <label
                    class="flex dark:text-gray-50 font-serif text-gray-700 text-base font-bold mb-2"
                    >Explain Image</label
                  >
                  <div
                    class="flex justify-center mt-2 bg-gray-100 rounded-lg h-32 w-32"
                  >
                    <div
                      class="w-2xl rounded-lg max-h-52 overflow-hidden scale-125"
                    >
                      <div>
                        <img
                          v-if="q.imageName"
                          class="m-auto rounded-lg h-auto w-32 max-h-52"
                          :src="require(`@/assets/${q.imageName}.jpeg`)"
                        />
                        <div v-if="q.checkAnswerMedia">
                          <div v-if="q.checkAnswerMedia.mediaType != 'video'">
                            <img
                              v-if="q.checkAnswerImage"
                              class="m-auto mt-6 h-auto w-32 max-h-52"
                              :src="q.checkAnswerImage"
                            />
                          </div>
                        </div>
                        <div v-if="q.checkAnswerMedia">
                          <div
                            class="w-2xl rounded-lg mt-6 h-2xl scale-125"
                            v-if="q.checkAnswerMedia.mediaType === 'video'"
                          >
                            <video
                              v-if="q.checkAnswerImage"
                              controls
                              ref="videoPlayer"
                              class="video-js w-2xl rounded-lg h-2xl scale-125"
                            >
                              <source :src="q.checkAnswerImage" />
                            </video>
                            <div class="videoAnswer bg-black"></div>
                          </div>
                        </div>
                        <img
                          @click="showDialogCheckSnippy(index)"
                          v-if="!q.imageName && !q.checkAnswerImage"
                          class="m-auto mt-12 h-8 w-9 max-h-52"
                          ref="img"
                          :src="require('@/assets/form-image1.png')"
                        />
                        <input
                          ref="image"
                          style="visibility: hidden"
                          @change="hanldeChange"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-row"
                v-if="question.type === 'picture_answer'"
              >
                <div>
                  <button
                    class="bg-mygray w-6 h-6 hover:bg-black-100 text-white font-bold rounded-full flex float-right m-1"
                    @click="deleteAnswImage(index)"
                  >
                    <p class="m-auto">✕</p>
                  </button>
                  <label
                    class="flex dark:text-gray-50 font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Image</label
                  >
                  <div
                    class="flex justify-center mt-2 bg-gray-100 rounded-lg h-32 w-32"
                  >
                    <div
                      class="w-full rounded-lg h-full overflow-hidden"
                      :style="{
                        background:
                          'left/100% no-repeat url(' + q.answerImage + ')',
                      }"
                    >
                      <div>
                        <div
                          class="flex flex-col"
                          @dragover.prevent
                          @drop.prevent
                        >
                          <img
                            @click="handleClickAnswImg(index)"
                            v-if="!q.answerImage"
                            class="m-auto mb-4 mt-5 h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/form-image1.png')"
                          />
                          <!--     <input
                            type="file"
                            ref="imageAnsw"
                            style="visibility: hidden"
                            class="bg-transparent"
                            accept="image/png, image/gif, image/jpeg"
                            multiple
                            @change="uploadFileAnswer"
                          /> -->
                          <img
                            @click="handClickAnswer(index)"
                            v-if="!q.answerImage"
                            class="m-auto h-8 w-9 max-h-52"
                            ref="img"
                            :src="require('@/assets/uploadImage.png')"
                          />
                          <input
                            ref="image"
                            style="visibility: hidden"
                            @change="hanldeChange"
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable2>
        <button
          @click="addAnswer"
          class="cursor-pointer text-mygreen font-serif"
        >
          +Add Answer
        </button>
      </div>
    </div>
    <my-dialog v-model:show="showDialogImage">
      <chose-image-popup
        :typeImage="typeImg"
        :indexImage="index"
        @add="addImage"
        @addQuestImg="addQuestImg"
        @addQuestSnippy="addQuestSnippy"
        @addQuestSnippyWrong="addQuestSnippyWrong"
        @addQuestSnippyCorrect="addQuestSnippyCorrect"
      >
      </chose-image-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogChoseImage">
      <add-image-main :type="typeChoose" @update="uploadFile"> </add-image-main>
    </my-dialog>
    <my-dialog v-model:show="showDialogChoseImageAnswer">
      <add-image-answer
        :type="typeChoose"
        :crop="crop"
        :index="index"
        @update="uploadFileAnswer"
      >
      </add-image-answer>
    </my-dialog>
    <my-dialog v-model:show="showDialogImageSnippy">
      <chose-level-image-popup
        :cropp="crop"
        :indexImage="indexSnipy"
        :typeSnippy="typeSnippy"
        :isNoImage="isNoImage"
        @add="addImageName"
        @addOrdering="addOrderingSnippy"
        @addQuestExpImg="addQuestExpImg"
        @uploadFileCheckImage="uploadFileCheckImage"
      >
      </chose-level-image-popup>
    </my-dialog>
    <my-dialog v-model:show="showDialogMoveTo" :title="'Move question'">
      <move-to-dialog
        :indexImage="indexSnipy"
        :questionId="questionId"
        @add="moveToFunc"
      >
      </move-to-dialog>
    </my-dialog>
  </div>
</template>

<script>
/* import getCollection from "@/composables/getCollectionUnits";
import getCollectionOrder from "@/composables/getCollectionUnitsOrder";
import firebase from "firebase/compat";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";*/
import { db } from "@/firebase/config";
import imageCompression from "browser-image-compression";
/* import { useRouter } from "vue-router"; */
import { computed, toRefs, reactive, ref, watch } from "vue";
import ChoseLevelImagePopup from "./choseLevelImagePopup.vue";
import MoveToDialog from "./moveToDialog.vue";
import ChoseImagePopup from "./imageModal";
import MyDialog from "@/components/MyDialog";
import AddImageMain from "./addImageMain";
import AddImageAnswer from "./addImageAnswer";
import { VueDraggableNext } from "vue-draggable-next";
import { createToast } from "mosha-vue-toastify";
import useStorageLibrary from "@/composables/useStorageLibrary";
import Toggle from "@vueform/toggle";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { TrashIcon } from "@heroicons/vue/solid";
import { getStorage, getMetadata } from "firebase/storage";
import { ref as storageRef } from "firebase/storage";
/* import { toRefs, toRef } from 'vue' */
export default {
  props: {
    type: String,
    quest: Object,
    levelId: String,
    questionsArr: Object,
    trackField: Boolean,
  },
  components: {
    Toggle,
    ChoseImagePopup,
    MoveToDialog,
    AddImageMain,
    AddImageAnswer,
    Loading,
    ExclamationCircleIcon,
    ChoseLevelImagePopup,
    TrashIcon,
    MyDialog,
    draggable2: VueDraggableNext,
  },
  setup(props, context) {
    const state = reactive({
      answersArr: [],
      crop: 5 / 4,
      showDialogImage: false,
      showDialogImageSnippy: false,
      showDialogMoveTo: false,
      showDialogAddToDish: false,
      showDialogChoseImage: false,
      showDialogChoseImageAnswer: false,
      typeImg: "",
      typeChoose: "",
      whenClickInpute: false,
      typeSnippy: "",
      isImage: false,
      isNoImage: true,
      indexSnipy: null,
      imageName: null,
      questionId: null,
      titleQuest: null,
      fullPage: true,
      checkNullAnsw: [],
      videoTags: [],
      filePath: null,
      checkAnswerImage: null,
      remainingCount: null,
      file: [],
      fileType: "image",
      maxCount: 140,
      fileAnsw: [],
      answerImage: null,
      isVideo: false,
      index: null,
    });
    const { url, uploadImage } = useStorageLibrary();
    const AddFile = ref([]);
    const imageAnsw = ref([]);
    let isLoading = ref(false);
    const treckField = ref(false);
    const addAnswer = () => {
      /* state.videoOptions.sources[0].src = props.quest.image */
      if (
        question.value.type === "fast_question" &&
        state.answersArr.length >= 2
      ) {
        createToast(
          {
            title: "Warning",
            description:
              "No more than two answers are available for this type of question",
          },
          {
            type: "warning",
            position: "top-center",
            transition: "bounce",
          }
        );
      } else {
        answers.value.push({
          title: null,
          explainTitle: null,
          explainDescription: null,
          isCorrect: false,
        });
      }
    };

    const deleteElement = (index) => {
      state.answersArr.splice(index, 1);
    };
    const deleteAnswExpImage = (index) => {
      state.answersArr[index].imageName = null;
      state.answersArr[index].checkAnswerMedia = null;
      state.answersArr[index].checkAnswerImage = null;
    };
    const deleteMainImage = () => {
      question.value.image = null;
      question.value.media = null;
    };
    const checkVideo = computed(() => {
      if (props.quest.media) {
        if (props.quest.media.mediaType) {
          if (props.quest.media.mediaType === "video") {
            return true;
          }
        }
      } else {
        return false;
      }
      return false;
    });
    const deleteAnswImage = (ind) => {
      state.answersArr[ind].answerImage = null;
      state.answersArr[ind].mediaItemId = null;
      state.answersArr[ind].media = null;
    };
    const handleClick = () => {
      state.showDialogImage = true;
      state.typeImg = "questionImage";
    };
    const handleClickAnswImg = (ind) => {
      state.showDialogImage = true;
      state.typeImg = "answerImage";
      state.index = ind;
    };
    const dragFile = async (e) => {
      let data = e.dataTransfer.files[0];
      const compressedFile = await imageCompression(data, {
        maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
        maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
        useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
        // advanced options
        // optional, see https://stackoverflow.com/a/32490603/10395024
        initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
      });
      state.file = compressedFile; // true
      createImage();
    };
    const uploadFile = async (newImage, file) => {
      console.log(newImage, "newImage");
      state.tags = newImage.tags;
      state.titleQuest = newImage.title;
      isLoading.value = true;
      if (file.type === "image/gif") {
        const selected = file;

        if (selected) {
          state.file = selected;
          state.filePath = URL.createObjectURL(state.file);
        }
      }
      if (file.type === "video/mp4") {
        const selected = file;
        if (selected) {
          state.file = selected;
          state.filePath = URL.createObjectURL(state.file);
          state.fileType = "video";
        }
      } else {
        const selected = file;

        if (selected) {
          state.file = selected;
          state.filePath = URL.createObjectURL(state.file);
        }
      } // true
      createImage();
    };
    const dragFileAnswer = async (e) => {
      let data = e.dataTransfer.files[0];
      const compressedFile = await imageCompression(data, {
        maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
        maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
        useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
        // advanced options
        // optional, see https://stackoverflow.com/a/32490603/10395024
        initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
      });
      state.fileAnsw = compressedFile;
      createImageQuestion();
    };
    const uploadFileCheckImage = async (newImage, file) => {
      console.log(newImage, "newImage");
      state.tags = newImage.tags;
      state.titleQuest = newImage.title;
      if (file.type === "image/gif") {
        const selected = file;

        if (selected) {
          state.fileAnsw = selected;
          state.filePath = URL.createObjectURL(state.fileAnsw);
        }
      } else {
        const selected = file;

        if (selected) {
          state.fileAnsw = selected;
          state.filePath = URL.createObjectURL(state.fileAnsw);
        }
      }
      createCheckImageQuestion();
    };
    const uploadFileAnswer = async (newImage, file) => {
      console.log(newImage, "newImage");
      state.tags = newImage.tags;

      state.titleQuest = newImage.title;
      if (file.type === "image/gif") {
        const selected = file;

        if (selected) {
          state.fileAnsw = selected;
          state.filePath = URL.createObjectURL(state.fileAnsw);
        }
      } else {
        const selected = file;

        if (selected) {
          state.fileAnsw = selected;
          state.filePath = URL.createObjectURL(state.fileAnsw);
        }
      }
      createImageQuestion();
      console.log(state.fileAnsw);
    };
    const createCheckImageQuestion = async () => {
      isLoading.value = true;
      let newImage = {
        type: "image",
        tags: state.tags,
        iconLink: null,
        title: state.titleQuest,
      };
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(state.fileAnsw, "media-library/" + a.id);
          console.log(a.id);
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .then((info) => {
              console.log(info, "infoooo"),
                (state.answersArr[state.indexSnipy].imageName = null),
                (state.answersArr[state.indexSnipy].checkAnswerMedia = {
                  mediaItemId: a.id,
                  mediaLink: url.value,
                }),
                (state.answersArr[state.indexSnipy].mediaItemId = a.id),
                (state.filePath = url.value),
                (state.answersArr[state.indexSnipy].checkAnswerImage =
                  url.value),
                (state.checkAnswerImage = url.value);
              const storage = getStorage();
              const forestRef = storageRef(
                storage,
                `media-library/${a.id}/original`
              );

              // Get metadata properties
              getMetadata(forestRef)
                .then((metadata) => {
                  let type = metadata.contentType.split("/")[1];
                  if (type === "jpeg" || type === "jpg" || type === "png") {
                    state.answersArr[
                      state.indexSnipy
                    ].checkAnswerMedia.mediaType = "image";
                  } else if (type === "gif") {
                    state.answersArr[
                      state.indexSnipy
                    ].checkAnswerMedia.mediaType = "gif";
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              state.showDialogImageSnippy = false;
              isLoading.value = false;
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    };
    const createImageQuestion = async () => {
      isLoading.value = true;
      let newImage = {
        type: "image",
        tags: state.tags,
        iconLink: null,
        title: state.titleQuest,
      };
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(state.fileAnsw, "media-library/" + a.id);
          console.log(a.id);
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .then((info) => {
              console.log(info, "infoooo"),
                (state.answersArr[state.index].media = {
                  mediaItemId: a.id,
                  mediaLink: url.value,
                }),
                (state.answersArr[state.index].mediaItemId = a.id),
                (state.filePath = url.value),
                (state.answersArr[state.index].answerImage = url.value),
                (state.answerImage = url.value);
              if (question.value.type === "fast_question") {
                state.isImage = true;
              }
              const storage = getStorage();
              const forestRef = storageRef(
                storage,
                `media-library/${a.id}/original`
              );

              // Get metadata properties
              getMetadata(forestRef)
                .then((metadata) => {
                  let type = metadata.contentType.split("/")[1];
                  if (type === "jpeg" || type === "jpg" || type === "png") {
                    state.answersArr[state.index].media.mediaType = "image";
                  } else if (type === "gif") {
                    state.answersArr[state.index].media.mediaType = "gif";
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              state.showDialogChoseImageAnswer = false;
              isLoading.value = false;
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    };
    /* Create Image for Main Image */
    const createImage = async () => {
      isLoading.value = true;
      let newImage = {
        type: state.fileType,
        tags: state.tags,
        iconLink: null,
        title: state.titleQuest,
      };
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(state.file, "media-library/" + a.id);
          question.value.mediaItemId = a.id;
          question.value.media = {
            mediaItemId: a.id,
          };
          const storage = getStorage();
          const forestRef = storageRef(
            storage,
            `media-library/${a.id}/original`
          );

          // Get metadata properties
          getMetadata(forestRef)
            .then((metadata) => {
              let type = metadata.contentType.split("/")[1];
              if (type === "jpeg" || type === "jpg" || type === "png") {
                question.value.media.mediaType = "image";
              } else if (type === "gif") {
                question.value.media.mediaType = "gif";
              } else {
                question.value.media.mediaType = "video";
                checkVideo.value = true;
              }
              state.showDialogChoseImage = false;
            })
            .catch((error) => {
              console.log(error);
            });
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .then((info) => {
              console.log(info, "infoooo"),
                (state.filePath = url.value),
                (question.value.image = url.value),
                (state.answerImage = url.value);
              (question.value.media.mediaLink = url.value),
                (isLoading.value = false);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    };
    const handClick = () => {
      /* AddFile.value.click();
      console.log(AddFile); */
      state.showDialogChoseImage = true;
      state.typeChoose = "Add";
    };
    const handClickAnswer = (ind) => {
      /* imageAnsw.value.click();
      state.index = ind;
      console.log(ind); */
      state.index = ind;
      state.showDialogChoseImageAnswer = true;
      state.typeChoose = "Add";
      if (question.value.type === "matching_questions") {
        console.log("matching 16/9");
        state.crop = 5 / 3;
      } else {
        state.crop = 5 / 4;
      }
    };
    const handleClickAnswSnippy = () => {
      state.imageName = "answer_type_0";
      state.showDialogImage = true;
      state.typeImg = "answerImageSnippy";
    };
    const handleClickAnswSnippyWrong = () => {
      state.wrongExplain.imageName = "answer_type_0";
      state.showDialogImage = true;
      state.typeImg = "answerImageSnippyWrong";
    };
    const handleClickAnswSnippyCorrect = () => {
      state.correctExplain.imageName = "answer_type_0";
      state.showDialogImage = true;
      state.typeImg = "answerImageSnippyCorrect";
    };
    const addImage = (id, link) => {
      const storage = getStorage();
      const forestRef = storageRef(storage, `media-library/${id}/original`);

      // Get metadata properties
      getMetadata(forestRef)
        .then((metadata) => {
          question.value.media = {
            mediaItemId: id,
            mediaLink: link,
          };

          let type = metadata.contentType.split("/")[1];
          if (type === "jpeg" || type === "jpg" || type === "png") {
            question.value.media.mediaType = "image";
            checkVideo.value = false;
          } else if (type === "gif") {
            question.value.media.mediaType = "gif";
            checkVideo.value = false;
          } else {
            question.value.media.mediaType = "video";
            checkVideo.value = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          (question.value.mediaItemId = id),
            (state.filePath = link),
            (question.value.image = link),
            (state.answerImage = link),
            (state.showDialogImage = false);
        });
    };
    const addQuestExpImg = (id, link, ind) => {
      state.answersArr[ind].imageName = null;
      const storage = getStorage();
      const forestRef = storageRef(storage, `media-library/${id}/original`);
      // Get metadata properties
      getMetadata(forestRef)
        .then((metadata) => {
          let type = metadata.contentType.split("/")[1];
          state.answersArr[ind].checkAnswerMedia = {
            mediaItemId: id,
            mediaLink: link,
          };
          if (type === "jpeg" || type === "jpg" || type === "png") {
            state.answersArr[ind].checkAnswerMedia.mediaType = "image";
          } else if (type === "gif") {
            state.answersArr[ind].checkAnswerMedia.mediaType = "gif";
          } else {
            state.answersArr[ind].checkAnswerMedia.mediaType = "video";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          state.showDialogImageSnippy = false;
          (state.filePath = link),
            (state.answersArr[ind].checkAnswerImage = link),
            (state.checkAnswerImage = link),
            (state.showDialogImage = false);
        });
    };
    const addQuestImg = (id, link, ind) => {
      const storage = getStorage();
      const forestRef = storageRef(storage, `media-library/${id}/original`);

      // Get metadata properties
      getMetadata(forestRef)
        .then((metadata) => {
          let type = metadata.contentType.split("/")[1];
          state.answersArr[ind].media = {
            mediaItemId: id,
            mediaLink: link,
          };
          if (type === "jpeg" || type === "jpg" || type === "png") {
            state.answersArr[ind].media.mediaType = "image";
          } else if (type === "gif") {
            state.answersArr[ind].media.mediaType = "gif";
          } else {
            state.answersArr[ind].media.mediaType = "video";
          }
          if (question.value.type === "fast_question") {
            state.isImage = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          if (state.answersArr[ind].media.mediaType === "video") {
            createToast(
              {
                title: "Failure",
                description: "You can't select a video here",
              },
              {
                type: "danger",
                position: "top-center",
                transition: "bounce",
              }
            );
            state.showDialogImage = false;
          } else {
            (state.answersArr[ind].mediaItemId = id),
              (state.filePath = link),
              (state.answersArr[ind].answerImage = link),
              (state.answerImage = link),
              (state.showDialogImage = false);
          }
        });
    };
    const addImageName = (src, ind) => {
      console.log(src);
      state.answersArr[ind].imageName = src;
      state.showDialogImageSnippy = false;
      state.answersArr[ind].checkAnswerMedia = null;
    };
    const addOrderingSnippy = (src) => {
      question.value.imageName = src;
      state.showDialogImageSnippy = false;
    };

    const showDialogSnippy = (ind) => {
      state.showDialogImageSnippy = true;
      state.indexSnipy = ind;
      state.isNoImage = true;
    };
    const showDialogCheckSnippy = (ind) => {
      state.showDialogImageSnippy = true;
      state.indexSnipy = ind;
      state.isNoImage = false;
      state.crop = 5 / 3;
      console.log(state.crop, state.crop, state.crop, state.crop, state.crop);
    };
    const showDialogSnippyCorrect = (ind) => {
      state.showDialogImageSnippy = true;
      state.typeSnippy = ind;
      state.isNoImage = true;
    };
    const openDialogMoveTo = (id) => {
      state.showDialogMoveTo = true;
      state.questionId = id;
    };
    const moveToFunc = (id) => {
      state.showDialogMoveTo = false;
      question.value.levels.splice(0, 1);
      question.value.levels.push(id);
      db.collection("Question").doc(question.value.id).update(question.value);
      context.emit("delete", question.value.id, question.value.id);
    };
    const deleteHandleClick = () => {
      db.collection("Question")
        .doc(question.value.id)
        .delete()
        .catch((e) => console.log(e));
      context.emit("delete", question.value.id, question.value.id);
    };
    const cloneHandleClick = () => {
      isLoading.value = true;
      let clone = null;
      if (question.value.media) {
        clone = {
          randomize: false,
          isLive: false,
          media: {
            mediaItemId: question.value.mediaItemId
              ? question.value.mediaItemId
              : null,
            mediaLink: question.value.image ? question.value.image : null,
            mediaType: question.value.media.mediaType
              ? question.value.media.mediaType
              : null,
          },
          answers: question.value.answers,
          question: question.value.question,
          type: question.value.type,
          levels: question.value.levels,
          image: question.value.image ? question.value.image : null,
          mediaItemId: question.value.mediaItemId
            ? question.value.mediaItemId
            : null,
          explainTitle: question.value.explainTitle
            ? question.value.explainTitle
            : null,
          explainDescription: question.value.explainDescription
            ? question.value.explainDescription
            : null,
          order: null,
          imageName: question.value.imageName ? question.value.imageName : null,
        };
      } else {
        clone = {
          randomize: false,
          isLive: false,
          answers: question.value.answers,
          question: question.value.question,
          type: question.value.type,
          levels: question.value.levels,
          image: question.value.image ? question.value.image : null,
          mediaItemId: question.value.mediaItemId
            ? question.value.mediaItemId
            : null,
          explainTitle: question.value.explainTitle
            ? question.value.explainTitle
            : null,
          explainDescription: question.value.explainDescription
            ? question.value.explainDescription
            : null,
          order: null,
          imageName: question.value.imageName ? question.value.imageName : null,
        };
      }

      db.collection("LevelContent")
        .doc(props.levelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          let levels = snapshot.data();
          let max =
            props.questionsArr && props.questionsArr.length
              ? props.questionsArr.slice().sort((a, b) => b.order - a.order)[0]
                  .order + 1
              : 0;
          clone.order = max;
          clone.createdAt = Date.now();
          db.collection("Question")
            .add(clone)
            .then(async (i) => {
              console.log(i.id);
              context.emit("changeA", i.id, clone);
              levels.questionIds.push(i.id);
              db.collection("LevelContent")
                .doc(props.levelId)
                .update({ questionIds: levels.questionIds })
                .catch((e) => console.log(e));
              (isLoading.value = false),
                (treckField.value = false),
                createToast(
                  {
                    title: "Success",
                    description: "Question successfully cloned",
                  },
                  {
                    type: "success",
                    position: "top-center",
                    transition: "bounce",
                  }
                );
            })
            .catch((e) => console.log(e));
        });
      state.checkNullAnsw = [];
    };
    const question = computed(() => {
      return props.quest;
    });

    const answers = computed(() => {
      if (props.quest.answers) {
        props.quest.answers.map((a) => {
          if (a.media) {
            state.isImage = true;
          } else {
            state.isImage = false;
          }
        });
      }
      return props.quest.answers;
    });
    const createQuest = async () => {
      state.checkNullAnsw = [];
      if (question.value.isLive === true) {
        treckField.value = true;
      } else {
        treckField.value = false;
      }
      if (question.value.type === "knowledge_question") {
        question.value.answers.map((a) => {
          Object.keys(a).every(function (key) {
            console.log(a);
            if (!a.title || !a.explainTitle) {
              state.checkNullAnsw.push(false);
              console.log(key);
            } else {
              state.checkNullAnsw.push(true);
            }
          });
        });
      }
      if (question.value.type === "multiselect_questions") {
        question.value.answers.map((a) => {
          Object.keys(a).every(function (key) {
            console.log(a);
            if (!a.title || !question.value.imageName) {
              state.checkNullAnsw.push(false);
              console.log(key);
            } else {
              state.checkNullAnsw.push(true);
            }
          });
        });
      }
      if (question.value.type === "matching_questions") {
        question.value.answers.map((a) => {
          Object.keys(a).every(function (key) {
            console.log(a);
            if (!a.title) {
              state.checkNullAnsw.push(false);
              console.log(key);
            } else {
              state.checkNullAnsw.push(true);
            }
          });
        });
      }
      if (question.value.type === "ordering_question") {
        question.value.answers.map((a) => {
          Object.keys(a).every(function (key) {
            console.log(a);
            if (!a.title) {
              state.checkNullAnsw.push(false);
              console.log(key);
            } else {
              state.checkNullAnsw.push(true);
            }
            console.log(state.checkNullAnsw, "3141");
          });
        });
      }
      if (question.value.type === "picture_answer") {
        question.value.answers.map((a) => {
          Object.keys(a).every(function (key) {
            console.log(a);
            if (!a.explainTitle) {
              state.checkNullAnsw.push(false);
              console.log(key);
            } else {
              state.checkNullAnsw.push(true);
            }
          });
        });
      }
      if (
        (!question.value.question || state.checkNullAnsw.includes(false)) &&
        question.value.isLive === true
      ) {
        createToast(
          {
            title: "Incorect data",
            description: "There are empty field",
          },
          {
            type: "danger",
            position: "top-center",
            transition: "bounce",
          }
        );
      } else {
        isLoading.value = true;
        if (props.type === "Update") {
          if (question.value.media) {
            question.value.media = {
              mediaItemId: question.value.mediaItemId,
              mediaLink: question.value.image,
              mediaType: question.value.media.mediaType,
            };
          }
          db.collection("Question")
            .doc(question.value.id)
            .update(question.value)
            .then(() => {
              (isLoading.value = false),
                (treckField.value = false),
                createToast(
                  {
                    title: "Success",
                    description: "Question successfully updated",
                  },
                  {
                    type: "success",
                    position: "top-center",
                    transition: "bounce",
                  }
                );
            })
            .catch((e) => console.log(e));
          state.checkNullAnsw = [];
        }
        if (props.type === "Add") {
          question.value.levels.push(props.levelId);
          isLoading.value = true;
          db.collection("LevelContent")
            .doc(props.levelId)
            .get()
            .then((snapshot) => {
              if (!snapshot.exists) return;
              let levels = snapshot.data();
              let max =
                props.questionsArr && props.questionsArr.length
                  ? props.questionsArr
                      .slice()
                      .sort((a, b) => b.order - a.order)[0].order + 1
                  : 0;
              if (question.value.media) {
                question.value.media = {
                  mediaItemId: question.value.mediaItemId,
                  mediaLink: question.value.image,
                  mediaType: question.value.media.mediaType,
                };
              }
              question.value.order = max;
              question.value.createdAt = Date.now();
              db.collection("Question")
                .add(question.value)
                .then(async (i) => {
                  context.emit("changeA", i.id, question.value);
                  levels.questionIds.push(i.id);
                  db.collection("LevelContent")
                    .doc(props.levelId)
                    .update({ questionIds: levels.questionIds })
                    .catch((e) => console.log(e));
                  (isLoading.value = false),
                    (treckField.value = false),
                    createToast(
                      {
                        title: "Success",
                        description: "Question successfully added",
                      },
                      {
                        type: "success",
                        position: "top-center",
                        transition: "bounce",
                      }
                    );
                })
                .catch((e) => console.log(e));
            });
          state.checkNullAnsw = [];
        }
      }
    };
    state.answersArr = answers;
    watch(
      () => question.value.type,
      (first, second) => {
        if (first === "fast_question" && props.type === "Add") {
          state.answersArr.splice(0, 2);
        }
        console.log(second);
      }
    );

    return {
      ...toRefs(state),
      question,
      answers,
      createQuest,
      AddFile,
      createCheckImageQuestion,
      handClick,
      moveToFunc,
      handleClick,
      imageAnsw,
      addAnswer,
      treckField,
      deleteAnswImage,
      addImage,
      dragFile,
      checkVideo,
      addQuestExpImg,
      createImageQuestion,
      showDialogSnippyCorrect,
      addQuestImg,
      showDialogSnippy,
      addImageName,
      uploadFile,
      uploadFileAnswer,
      openDialogMoveTo,
      uploadFileCheckImage,
      cloneHandleClick,
      dragFileAnswer,
      createImage,
      showDialogCheckSnippy,
      deleteAnswExpImage,
      isLoading,
      deleteElement,
      deleteMainImage,
      addOrderingSnippy,
      handleClickAnswImg,
      deleteHandleClick,
      handleClickAnswSnippy,
      handleClickAnswSnippyWrong,
      handClickAnswer,
      handleClickAnswSnippyCorrect,
    };
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.videoMain {
  width: 270px;
  height: 144px;
}
.active-img {
  border: 1px solid #51a045;
  border-radius: 20%;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 3px;
}
.toggle-red {
  --toggle-bg-on: black;
  --toggle-border-on: black;
}
.vue3-player-video .transition {
  display: none;
}
.vs-carousel {
  position: relative;
  width: 300px;
}

.vs-carousel__slide {
  flex: 0 0 33%;
  height: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.active-li {
  filter: blur(3px);
}

.modal-overlay {
  background-color: #000000da;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.w-d {
  width: 315px;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block {
  background-color: #0e0e0e;
}
/* Toggle Q */
input:checked ~ .dot1 {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block1 {
  background-color: #0e0e0e;
}
.modal {
  text-align: center;
  background-color: white;
  width: 391px;
  height: 449px;
  margin-top: 10%;
  padding: 20px 0;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
}
.btn-add {
  font-size: 14px;
  color: #51a045;
  font: bold;
}
</style>
