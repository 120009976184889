import { ref } from "vue";
import { projectStorage } from "../firebase/config";

const useStorageLibrary = () => {
  const error = ref();
  const url = ref(null);
  const filePath = ref(null);
  const org = ref("original");

  const uploadImage = async (file, path) => {
    // filePath.value = `covers/${user.value.uid}/${file.name}`;
    filePath.value = `${path}/${org.value}`;
console.log(file)
console.log(path)
    const storageRef = projectStorage.ref(filePath.value);
    console.log(filePath)
    console.log(storageRef)
    try {
      let res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteImage = async (path) => {
    const storageRef = projectStorage.ref(path);

    try {
      await storageRef.delete();
    } catch (err) {
      console.log(err.message);
      error.value = err;
    }
  };

  return { url, filePath, org, error, uploadImage, deleteImage };
};
export default useStorageLibrary;
