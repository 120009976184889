<template>
  <div class="w-full p-2 dark:bg-bggraym scrollbar-hide  bg-gray-100 min-h-screen md:p-6 lg:p-16 screen-media">
    <div
      class="w-full bg-mywhite dark:bg-bggrayl scrollbar-hide  min-h-screen flex flex-row rounded-3xl lg:p-3 screen-media"
    >
      <table class="table-fixed scrollbar-hide w-100% text-left">
        <pulse-loader :loading="true" :color="green" :size="100"></pulse-loader>
        <thead>
          <tr>
            <th class="dark:text-gray-50">Dishes</th>
          </tr>
        </thead>
        <tbody>
          <tr class="scrollbar-hide">
            <td
              class="align-top level scrollbar-hide border-r dark:text-gray-50 border-gray-200 max-h-screen overflow-scroll"
              style="height: 800px"
            >
              <div class="flex flex-col w-72 scrollbar-hide max-h-screen overflow-scroll p-2">
                <div class="flex flex-row">
                  <button
                    @click="openDialogAddLevel"
                    class="mt-5 btn-add mr-auto"
                  >
                    + Add Dish
                  </button>
                  <button
                    @click="openDialogFilter"
                    class="mt-5 ml-auto btn-add float-right"
                  >
                    <i class="fas fa-filter"></i>
                  </button>
                </div>
                <input
                  v-model="searchQuery"
                  placeholder="Search"
                  class="mt-2 mb-2 pl-2 border rounded-lg float-right"
                />
                <draggable
                  class="dragArea list-group w-full"
                  :list="searchedProducts"
                  @dragend="updLeve"
                >
                  <div
                    @click="levelHandleClick(level)"
                    class="list-group-item flex flex-row p-3 font-bold border border-gray-200 rounded-md mb-1"
                    v-for="level in searchedProducts"
                    :key="level.id"
                    :class="{ 'active-li': levelActive === level.id }"
                  >
                    <div class="flex flex-row pt-3 pr-3"></div>
                    <div class="flex-col justify-center w-full">
                      <p>{{ level.title }}</p>
                      <p class="font-normal">{{ level.subTitle }}</p>
                    </div>

                    <div class="flex flex-row">
                      <div
                        class="edit ml-3"
                        @click="openDialogUpdateLevel(level)"
                      ></div>
                      <div
                        v-if="levelActive === level.id"
                        class="icon ml-3 max-h-8"
                      ></div>
                    </div>
                  </div>
                </draggable>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex w-60 h-9 my-72 mx-auto" v-if="!showDialogLevel">
        <p class="text-lg font-sans text-gray-400 text-center">
          Please select dish to see details
        </p>
      </div>
      <level-form
        v-show="showDialogLevel === true"
        :level="dishForm"
        :type="type"
        :levels="levelsData"
        @close="closeContent"
        @create="createDish"
      ></level-form>
    </div>
    <my-dialog v-model:show="showCheckbox" :title="'Filter'">
      <multi-check-box
        v-model:value="filterValue"
        :options="options"
        @clear="clearData"
      >
      </multi-check-box>
    </my-dialog>
  </div>
</template>

<script>
import getCollectionUnit from "@/composables/getCollectionDescOrder";
import { VueDraggableNext } from "vue-draggable-next";
import { onMounted } from "vue";
/* import firebase from "firebase/compat";
import { useRouter } from "vue-router"; */
import { DateTime } from "luxon";
import { ref, computed } from "vue";
import LevelForm from "./dishesLevelpopup.vue";
import { db } from "@/firebase/config";
import { useRouter } from "vue-router";
import firebase from "firebase/compat";
import MyDialog from "@/components/MyDialog";
import MultiCheckbox from "./modals/multi-checkbox.vue";

export default {
  components: {
    draggable: VueDraggableNext,
    LevelForm,
    MyDialog,
    "multi-check-box": MultiCheckbox,
  },
  setup() {
    const { documents: dishes } = getCollectionUnit("Dish");
    let componentKey = ref(0);
    const showDialogLevel = ref(false);
    const levelActive = ref(null); //id level
    const { documents: contents } = getCollectionUnit("LevelContent");
    const levelsData = ref([]);
    const searchQuery = ref("");

    const router = useRouter();
    let showCheckbox = ref(false);
    const filterValue = ref([]);
    const dishForm = ref({
      docId: "",
      id: "",
      createdAt: "",
      mediaItemId: null,
      levelId: "",
      unitId: "",
      levelQuestions: [],
    });
    const type = ref(""); //id level
    const hideDialog = () => {
      showDialogLevel.value = false;
    };
    const getMyValue = () => {
      filterValue.value = [];
    };
    const clearData = () => {
      filterValue.value = [];
    };
    const openDialogFilter = () => {
      showCheckbox.value = true;
    };
    const options = ref([]);
    const getOptions = () => {
      options.value = [
        { id: 1, name: "Vegetarian", value: "vegetarian" },
        { id: 2, name: "Vegan", value: "vegan" },
        { id: 3, name: "Dairy Free", value: "dairy-free" },
        { id: 4, name: "Gluten Free", value: "gluten-free" },
        { id: 0, name: "Not Marked", value: "no" },
      ];
    };
    const searchedProducts = computed(() => {
      if (dishes.value && searchQuery.value) {
        return dishes.value.filter((product) => {
          return (
            product.title
              .toLowerCase()
              .indexOf(searchQuery.value.toLowerCase()) != -1
          );
        });
      } else if (filterValue.value && dishes.value) {
        if (filterValue.value != "no") {
          return (
            (filterValue.value.length &&
              dishes.value.filter(
                (topic) =>
                  JSON.stringify(topic.filters) ===
                  JSON.stringify(filterValue.value)
              )) ||
            dishes.value
          );
        } else {
          return (
            (filterValue.value.length &&
              dishes.value.filter((topic) => topic.filters.length === 0)) ||
            dishes.value
          );
        }
      } else {
        return dishes.value;
      }
    });

    const createDish = (id) => {
      let max = 0;
      console.log(max);
      db.collection("Dish")
        .doc(id)
        .update({ order: max })
        .then(() => {
          searchedProducts.value.forEach(async (element, index) => {
            element.order = index;
            firebase
              .firestore()
              .collection("Dish")
              .doc(element.id)
              .update({ order: index + 1 })
              .catch((error) => {
                console.log(error);
              });
          });
        });
      /* .then(() => {
         db.collection("LevelContent")
        .add({
        levelId: levelActive.value,
        unitId: unitActive.value,
        createdAt: DateTime.now().toMillis(),
        levelQuestions: [],
        })
      }); */
    };
    const levelHandleClick = (level) => {
      levelsData.value = [];
      level.levels.map((a) => {
        type.value = "Update";
        var data = contents.value.find((x) => x.id === a);
        let arrData = [];
        arrData.push(data);
        arrData.forEach((element) => {
          if (element !== undefined) {
            levelsData.value.push(data);
          }
        });
      });
      if (!level.filters) {
        level.filters = [];
      }
      levelActive.value = level.id;
      showDialogLevel.value = true;
      type.value = "Update";
      dishForm.value = level;
      console.log(level);
      router.push({
        query: {
          dish_active: levelActive.value,
        },
      });
    };

    const updLeve = () => {
      searchedProducts.value.forEach(async (element, index) => {
        console.log(element.title, index);
        element.order = index;
        firebase
          .firestore()
          .collection("Dish")
          .doc(element.id)
          .update({ order: index })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    const openDialogAddLevel = () => {
      levelActive.value = null;
      type.value = "Add";
      showDialogLevel.value = true;
      levelsData.value = [];
      dishForm.value = {
        title: null,
        createdAt: DateTime.now().toMillis(),
        isLive: false,
        filters: [],
        levels: [],
        order: 0,
        image: null,
      };
      router.push({
        query: {
          dish_active: "addLevel",
        },
      });
    };
    const closeContent = () => {
      hideDialog();
    };
    onMounted(() => {
      getOptions();
      getMyValue();
    });
    return {
      dishes,
      showDialogLevel,
      levelHandleClick,
      dishForm,
      levelsData,
      filterValue,
      componentKey,
      openDialogAddLevel,
      hideDialog,
      openDialogFilter,
      showCheckbox,
      type,
      getMyValue,
      getOptions,
      options,
      clearData,
      updLeve,
      searchedProducts,
      searchQuery,
      closeContent,
      levelActive,
      createDish,
    };
  },
};
</script>

<style scoped>
table {
  padding: 40px;
}
@media screen and (max-width: 600px) {
  .screen-media {
    min-height: 2100px;
  }
}
.align-top {
  max-width: 300px;
}
.nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
nu-modal {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 16px rgb(0 0 0 / 12%);
  position: absolute;
  top: 20%;
  left: 50%;
  width: 80%;
  margin-bottom: 50px;
  max-width: 1600px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
}
.active-li {
  border: 1px solid #eda81c;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.icon {
  border-radius: 50%;
  background: #eda81c;
  font-size: 14px;
}

.edit {
  display: none;
  cursor: pointer;
}

.active-li:hover .edit {
  display: block;
}

@media screen and (max-width: 500px) {
  .span {
    display: none;
  }
}

.sortable-ghost {
  visibility: hidden;
}

.btn-add {
  font-size: 14px;
  color: #51a045;
}
</style>
