<template>
  <input
    @input="(event) => $emit('update:checked', event.target.checked)"
    type="checkbox"
    :checked="checked"
    :id="fieldId"
    class="mr-2 hidden"
  />
  <label
    :for="fieldId"
    class="flex flex-row items-center font-serif text-gray-800 cursor-pointer select-none"
  >
    <i
      class="fa mr-2"
      :class="{
        'fa-check-circle text-green-600 border-2 border-green-600 rounded-full': checked,
        'fa-circle text-gray-100 border-2 border-green-600 rounded-full': !checked,
      }"
    ></i>
    {{ label.charAt(0).toUpperCase() + label.slice(1)}}
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>
