<template>
  <div class="w-full">
    <div class="flex flex-row px-7">
      <div class="edit ml-auto mr-5 block"></div>
    </div>

    <div
      class="bg-mywhite m-auto px-10 py-8 rounded-xl max-w-7xl overflow-scroll"
    >
      <div class="w-full h-3xl imageblock overflow-scroll">
        <div class="flex flex-wrap -mx-4 overflow-scroll">
          <div
            class="my-4 px-4 w-1/6 overflow-scroll flex flex-col"
            v-for="item of mediaLibrary"
            :key="item"
            @click="setImage(item.src)"
          >
            <div
              @click="handleClickAnswImg"
              class="w-full h-full flex flex-col"
            >
              <img
                class="w-47 h-44 rounded-lg"
                :src="`/levelImages/${item.src}.png`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["typeImage"],
  name: "add-questionlevel",
  data() {
    return {
      showDialogImage: false,
      searchQuery: "",
      mediaLibrary: [],
      type: "",
    };
  },
  methods: {
    setImage(i) {
      this.$emit("add", i);
    },
  },
  async created() {
    this.mediaLibrary = [
      { id: 1, src: "level_image_1" },
      { id: 2, src: "level_image_2" },
      { id: 3, src: "level_image_3" },
      { id: 4, src: "level_image_4" },
      { id: 5, src: "level_image_5" },
      { id: 6, src: "level_image_6" },
      { id: 7, src: "level_image_7" },
      { id: 8, src: "level_image_8" },
      { id: 9, src: "level_image_9" },
      { id: 10, src: "level_image_10" },
      { id: 11, src: "level_image_11" },
      { id: 12, src: "level_image_12" },
      { id: 13, src: "level_image_13" },
      { id: 14, src: "level_image_14" },
    ];
  },
};
</script>
<style>
.inp {
  width: 300px;
}
.imageblock {
  height: 500px;
  min-width: 1200px;
  overflow: scroll;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
