<template>
  <div class="w-full">
    <div class="flex flex-row px-7">
      <input
        type="search"
        class="form-control inp block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        v-model="searchQuery"
        placeholder="Search tag.."
      />
      <div class="edit ml-auto mr-5 block">
        <button @click="openDialogAddImage" class="fas fa-pencil-alt">
          Add Item
        </button>
      </div>
    </div>

    <div
      class="bg-mywhite m-auto px-10 py-8 rounded-xl max-w-7xl overflow-scroll"
    >
      <div class="w-full h-3xl imageblock overflow-scroll">
        <div class="flex flex-wrap -mx-4 overflow-scroll">
          <div
            class="my-4 px-4 w-1/6 overflow-scroll flex flex-col"
            v-for="item of searchedProducts"
            :key="item"
            @click="setImage(item)"
          >
            <div
              @click="handleClickAnswImg"
              class="w-full h-full flex flex-col"
            >
              <img   v-if="item.type === 'image'" class="w-47 h-44 rounded-lg" :src="item.iconLink" />
    
         <video v-if="item.type === 'video'" controls ref="videoPlayer" class="video-js object-cover w-47 h-44 rounded-lg pointer-events-none group-hover:opacity-75">
  <source :src="item.iconLink">
</video>
             
              <p>#{{ item.tags.join(" #") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-dialog v-model:show="showDialogImage">
      <image-form :image="ImageAddForm" :type="type" @create="createImage">
      </image-form>
    </my-dialog>
  </div>
</template>
<script>
import { db } from "@/firebase/config";
import MyDialog from "@/components/MyDialog";
import ImageForm from "../../media-library/components/add-item";
import useStorageLibrary from "@/composables/useStorageLibrary";
export default {
  props: ["typeImage","indexImage"],
  name: "add-questionlevel",
  components: {
    MyDialog,
    ImageForm,
  },
  data() {
    return {
      showDialogImage: false,
      searchQuery: "",
      mediaLibrary: [],
      type: "",
      index: null
    };
  },
  computed: {
    searchedProducts() {
      let images = this.mediaLibrary;
      if (images) {
        let filter = images.filter((image) => {
          if (image.tags)
            return image.tags.find(
              (a) =>
                a.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1
            );
        });

        return filter;
      }
      return null;
    },
  },
  methods: {
    async createImage(newImage, file) {
      console.log(newImage);
      const { url, uploadImage } = useStorageLibrary();
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(file, "media-library/" + a.id);
          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({ iconLink: url.value })
            .catch((e) => console.log(e));
          newImage.iconLink = url.value;
          this.showDialogImage = false;
          newImage.id = a.id
          await this.mediaLibrary.push(newImage);
          this.mediaLibrary.sort((a,b) => b.createdAt - a.createdAt)
        })
        .catch((e) => console.log(e));
    },
    setImage(i) {
      if (this.$props.typeImage === "questionImage") {
        this.$emit("add", i.id, i.iconLink)
        console.log(i.iconLink)
      }
      if (this.$props.typeImage === "answerImage") {
        this.$emit("addQuestImg", i.id, i.iconLink, this.$props.indexImage);
        console.log(this.$props.indexImage,"add1")
      }
      if (this.$props.typeImage === "answerExpalinImage") {
        this.$emit("addQuestExpImg", i.id, i.iconLink, this.$props.indexImage);
      }
      if (this.$props.typeImage === "LevelImage") {
        this.$emit("addLevelImg", i.id, i.iconLink);
         console.log(i.id, "add2")
      }
      if (this.$props.typeImage === "answerImageSnippy") {
        this.$emit("addQuestSnippy", i.id, i.iconLink);
         console.log(i.id, "add3")
      }
       if (this.$props.typeImage === "answerImageSnippyWrong") {
        this.$emit("addQuestSnippyWrong", i.id, i.iconLink);
         console.log(i.id, "add4")
      }
      if (this.$props.typeImage === "answerImageSnippyCorrect") {
        this.$emit("addQuestSnippyCorrect", i.id, i.iconLink);
         console.log(i.id)
      }
    },
    openDialogAddImage() { 
      this.showDialogImage = true;
      this.type = "Add";
    },
  },
  async created() {
    this.mediaLibrary = await db
      .collection("MediaLibrary")
      .orderBy("createdAt")
      .get()
      .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
       this.mediaLibrary.sort((a,b) => b.createdAt - a.createdAt)
  },
};
</script>
<style>
.inp {
  width: 300px;
}
.imageblock {
  height: 500px;
  min-width: 1200px;
  overflow: scroll;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
