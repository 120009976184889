<template>
  <div>
    <TransitionRoot as="template" :show="true">
      <Dialog as="div" class="relative z-10">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed z-99 inset-0 overflow-y-auto">
          <div
            class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative bg-mywhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
              >
                <div>
                  <div
                    class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                  >
                    <DocumentDuplicateIcon
                      class="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Confirmation
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Do you definitely want delete this document?
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <button
                    type="button"
                    class="w-full bg-blue-600 text-mywhite inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    @click="confirm"
                    v-shortkey.once="['enter']"
                    @shortkey="confirm"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    @click="cancel"
                    ref="cancelButtonRef"
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { DocumentDuplicateIcon } from "@heroicons/vue/outline";

export default {
  props: {
    questionId: String,
  },
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    DocumentDuplicateIcon,
  },
  setup(props, context) {
  console.log(props)
  let confirm =()=>{
      context.emit("delete")
  };
   let cancel =()=>{
      context.emit("cancel")
  }
    
    return {
      confirm,
      cancel
    };
  },
};
</script>
<!-- <script>
export default {
  props: ["typeImage","indexImage","typeSnippy"],
  name: "add-questionlevel",
  data() {
    return {
      showDialogImage: false,
      searchQuery: "",
      mediaLibrary: [],
      type: "",
    };
  },
  methods: {

  },
  async created() {
    this.mediaLibrary = [
      { id: 1, src: "answer_type_1" },
      { id: 2, src: "answer_type_2" },
      { id: 3, src: "answer_type_3" },
      { id: 4, src: "answer_type_4" },
      { id: 5, src: "answer_type_5" },
      { id: 6, src: "answer_type_6" },
      { id: 7, src: "answer_type_7" },
      { id: 8, src: "answer_type_8" },
      { id: 9, src: "answer_type_9" },
      { id: 10, src: "answer_type_10" },
    ];
  },
};
</script> -->
<style>
.inp {
  width: 300px;
}
.dialog {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    z-index: 7 !important;
}
.imageblock {
  height: 500px;
  min-width: 1200px;
  overflow: scroll;
}
.image {
  height: 200px;
  width: 200px;
}
</style>
