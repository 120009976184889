<template>
  <div class="w-full">
    <form @submit.prevent class="flex flex-row z-50">
      <div class="mr-3">
        <input
          ref="image"
          style="visibility: hidden"
          accept="image/png, image/gif, image/jpeg, video/mp4"
          @change="setImage"
          type="file"
        />
        <div>
          
         <video v-if="ImageAddForm.type === 'video'" controls ref="videoPlayer" class="video-js object-cover w-72">
  <source :src="filePath">
</video>
          <vue-cropper
            v-if="imgSrc"
            ref="cropper"
            :guides="true"
            :view-mode="1"
            drag-mode="crop"
            :scalable="true"
            :movable="true"
            :zoomable="true"
            :auto-crop-area="1"
            :min-container-width="250"
            :min-container-height="180"
            :background="true"
            :rotatable="false"
            :src="imgSrc"
            alt="Source Image"
            :img-style="{ width: '300px', height: '200px' }"
            :aspect-ratio="aspectRatio"
          ></vue-cropper>
        </div>
      </div>
      <div>
        <div class="mb-6 h-full mr-9">
          <label class="flex text-gray-700 font-serif text-sm font-bold mb-2"
            >Image</label
          >
          <div
            class="image"
            @click="handleClick"
            :style="{
              background: 'left/100% no-repeat url(' + filePath + ')',
            }"
          ></div>
        </div>
      </div>
      <div class="w-72">
        <div class="mb-6">
          <label
            class="flex font-serif tracking-wide text-gray-700 text-base font-bold mb-2"
            >Title</label
          >
          <input
            v-model="ImageAddForm.title"
            class="form-control h-11 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            placeholder="Your message"
          />
        </div>
        <div class="mb-6">
          <label class="flex text-gray-700 font-serif text-sm font-bold mb-2"
            >Tags</label
          >
          <div>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              placeholder="Add tag and press ENTER"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </div>
        </div>
        <div class="mb-6">
          <button
            class="w-full font-sans h-12 px-6 font-bold font-sans text-mywhite transition-colors duration-150 bg-mygreen rounded-full focus:border-current"
            @click="addHandleClick"
          >
            <div
              v-if="this.loading"
              class="animate-spin rounded-full h-5 w-5 border-b-2 border-mywhite m-auto"
            ></div>
            <span v-else>{{ $props.type }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import VueTagsInput from "@sipec/vue3-tags-input";
import imageCompression from "browser-image-compression";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  name: "image-form",
  props: ["image", "type"],
  components: {
    VueTagsInput,
    VueCropper,
  },
  data() {
    return {
      ImageAddForm: { type: "image", tags: [], iconLink: null, title: null },
      filePath: "",
      tag: "",
      tags: [],
      file: null,
      imageType: null,
      loading: false,
      aspectRatio: 5 / 3,
      errors: [],
      aspectRatioTrue: false,
      docId: null,
      imgSrc: null,
      cropImg: "",
    };
  },

  methods: {
    setImage(e) {
      this.imageType = e.target.files[0].type;
      if (e.target.files[0].type === "image/gif") {
        this.tags.push({ text: "gif" });
        const selected = e.target.files[0];
        this.ImageAddForm.type = "image";
        this.imgSrc = null;
        if (selected) {
          this.file = selected;
          this.filePath = URL.createObjectURL(this.file);
        }
      } else if (e.target.files[0].type === "video/mp4") {
        this.tags.push({ text: "video" });
        this.ImageAddForm.type = "video";
        const selected = e.target.files[0];
        this.imgSrc = null;
        if (selected) {
          this.file = selected;
          this.filePath = URL.createObjectURL(this.file);
        }
      } else {
        this.tags.push({ text: "5/3" });
        this.file = e.target.files[0];
        this.ImageAddForm.type = "image";
        if (!this.file.type.includes("image/")) {
          return;
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(this.file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },
    async cropImage() {
      /* // get image data for post processing, e.g. upload or setting image src
      this.filePath = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper
        .getCroppedCanvas()
        .toBlob((blob) => {
          (blob.name = this.file.name),
            (blob.lastModified = this.file.lastModified);
          this.file = blob;
          console.log(this.file, "cropp");
        })
        .then(() => {
          const compressedFile = imageCompression(this.file, {
            maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
            maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
            // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
            maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
            useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
            // advanced options
            // optional, see https://stackoverflow.com/a/32490603/10395024
            initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
          });
          console.log(compressedFile, "dffff"); // true

          if (compressedFile) {
            this.file = compressedFile;
          }
        }); */
    },
    rotate() {
      // guess what this does :)
      this.$refs.cropper.rotate(90);
    },
    async addHandleClick() {
      if (this.imageType === "image/png" || this.imageType === "image/jpeg") {
        this.filePath = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
          (blob.name = this.file.name),
            (blob.lastModified = this.file.lastModified);
          console.log(this.file, "NOT FILE")
          const compressedFile = await imageCompression(blob, {
            maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
            maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
            // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
            maxIteration: 10, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
            useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
            // advanced options
            // optional, see https://stackoverflow.com/a/32490603/10395024
            initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
          });
          console.log(compressedFile, "compresed FILE"); // true

          if (compressedFile) {
            if (this.$props.type === "Add") {
              let tags = this.tags.map((a) => a.text);
              this.ImageAddForm.tags = tags;
              this.loading = true;
              if (!this.ImageAddForm.tags.length > 0) {
                this.errors.push("Enter tags");
              }
              if (!this.ImageAddForm.title) {
                this.errors.push("Enter Title");
              }
              if (!compressedFile) {
                this.errors.push("Upload an image");
              }

              if (this.errors.length) {
                createToast(
                  {
                    title: "Incorect data ",
                    description: this.errors[0],
                  },
                  {
                    type: "danger",
                    position: "top-center",
                    transition: "bounce",
                  }
                );
              } else {
                this.$emit("update", this.ImageAddForm, compressedFile);
              }
              this.errors = [];
            }
          }
        });
      } else {
        if (this.$props.type === "Add") {
          let tags = this.tags.map((a) => a.text);
          this.ImageAddForm.tags = tags;
          this.loading = true;
          if (!this.ImageAddForm.tags.length > 0) {
            this.errors.push("Enter tags");
          }
          if (!this.ImageAddForm.title) {
            this.errors.push("Enter Title");
          }
          if (!this.file) {
            this.errors.push("Upload an image");
          }

          if (this.errors.length) {
            createToast(
              {
                title: "Incorect data ",
                description: this.errors[0],
              },
              {
                type: "danger",
                position: "top-center",
                transition: "bounce",
              }
            );
          } else {
            this.$emit("update", this.ImageAddForm, this.file);
          }
          this.errors = [];
        }
      }
    },
    async hanldeChange() {
      /*       const selected = e.target.files[0];

      const compressedFile = await imageCompression(selected, {
        maxSizeMB: 0.15, // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 1000, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
        maxIteration: 5, // Do not forget to check the Caveat part on the official doc.      // optional, a function takes one progress argument (percentage from 0 to 100)
        useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)=
        // advanced options
        // optional, see https://stackoverflow.com/a/32490603/10395024
        initialQuality: 0, // optional, initial quality value between 0 and 1 (default: 1)
      });
      console.log(compressedFile); // true

      if (compressedFile) {
        this.file = compressedFile;
        this.filePath = URL.createObjectURL(this.file);
      } */
    },
    handleClick() {
      this.$refs.image.click();
    },
  },
  mounted() {
    this.filePath = require("@/assets/form-label2.png");
    if (this.type === "Update") {
      this.ImageAddForm.type = this.$props.image.type;
      this.ImageAddForm.title = this.$props.image.title
        ? this.$props.image.title
        : null;
      this.ImageAddForm.iconLink = this.$props.image.iconLink
        ? this.$props.image.iconLink
        : null;
      this.filePath = this.$props.image.iconLink
        ? this.$props.image.iconLink
        : null;
      this.ImageAddForm.tags = this.$props.image.tags
        ? this.$props.image.tags
        : null;
      this.tags = this.$props.image.tags ? this.$props.image.tags : null;
      this.docId = this.$props.image.id;
    }
  },
};
</script>

<style scoped>
.toggle-red {
  --toggle-bg-on: black;
  --toggle-border-on: black;
}
.image {
  height: 100px;
  width: 100px;
}
</style>
