<template>
  <div>
    <div class="w-full dark:bg-bggraym dark:text-gray-50">
      <div class="h-50 py-4 md:flex block dark:bg-bggraym dark:text-gray-50 bg-gray-100 mb-10">
        <div class="md:flex-col">
          <p class="md:pl-14 text-3xl font-bold mb-3">User</p>
          <img
            src="../assets/logo-user.png"
            class="justify-start mx-auto object-scale-down md:flex h-48 ml-3 pl-10 max-h-16"
          />
        </div>
        <div class="flex-col mt-12">
          <div>
            <p class="ml-7 font-serif font-light text-3xl" v-if="uName">
              {{ uName }}
            </p>
            <p class="ml-7 font-serif font-light text-3xl" v-else>
              (Anonymous)
            </p>
          </div>
          <div>
            <p
              class="ml-7 font-serif font-normal text-gray-500 text-m"
              v-if="createdAt"
            >
              Registered {{ convertDate(createdAt) }}
            </p>
          </div>
        </div>
        <div class="md:ml-auto pt-7 md:pr-4">
          <button
            class="md:flex p-4 px-7 rounded-full bg-mygreen text-mywhite border-mygreen border-2 font-bold disabled:text-green-600"
            @click="onSubmit"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!id"
      wire:loading
      class="fixed top-0 left-0 right-0 bottom-0 dark:bg-bggraym dark:text-gray-50 w-full h-screen z-50 overflow-hidden bg-gray-300 opacity-75 flex flex-col items-center justify-center"
    >
      <div
        class="loader dark:bg-bggraym dark:text-gray-50 ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"
      ></div>
    </div>
    <form v-else ss="bg-white md:px-10" @keydown="hasChanged = true">
      <div class="w-full dark:bg-bggraym dark:text-gray-50">
        <div class="inputs w-full p-6 px-20 mx-auto">
          <form class="mt-6 block md:flex w-full">
            <div class="flex-col w-full">
              <div class="w-full">
                <h2 class="flex text-2xl dark:text-gray-50 font-serif text-gray-600 pb-8">
                  User Information
                </h2>
                <div class="w-full md:w-full px-3 mb-6">
                  <label
                    class="flex font-serif dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Name</label
                  >
                  <input
                    v-model="uName"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                    type="text"
                    required
                  />
                </div>
                <div class="w-full md:w-full px-3 mb-6">
                  <label
                    class="flex font-serif dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold mb-2"
                    >Email</label
                  >
                  <input
                    v-model="uEmail"
                    disabled
                    class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                    type="text"
                    required
                  />
                </div>
                <div class="w-full md:w-full px-3 mb-6">
                  <label
                    class="flex font-serif dark:text-gray-50 tracking-wide text-gray-700 text-base font-bold mb-2"
                    >UID</label
                  >
                  <input
                    v-model="uId"
                    disabled
                    class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-400 shadow-inner rounded-md py-3 px-4 leading-tight focus:outline-none focus:border-gray-500"
                    type="text"
                    required
                  />
                </div>
                <div class="w-full md:w-full px-3 mb-6">
                  <label
                    class="flex tracking-wide dark:text-gray-50 font-serif text-gray-900 text-base font-bold mb-2"
                    >is Admin?</label
                  >
                  <div class="flex w-full mb-12">
                    <label
                      for="toggleAdm"
                      class="flex items-center cursor-pointer"
                    >
                      <div class="mr-8 dark:text-gray-50 text-base text-gray-700 font-medium">
                        Make user an Admin
                      </div>
                      <!-- toggle -->
                      <div class="relative">
                        <!-- input -->
                        <input
                          type="checkbox"
                          id="toggleAdm"
                          class="sr-only"
                          v-model="isAdmin"
                        />
                        <!-- line -->
                        <div
                          class="block bg-gray-200 w-14 h-8 rounded-full"
                        ></div>
                        <!-- dot -->
                        <div
                          class="dot absolute left-1 top-1 bg-mywhite w-6 h-6 rounded-full transition"
                        ></div>
                      </div>
                      <!-- label -->
                    </label>
                  </div>
                </div>
              </div>
              <!-- History start -->
              <div class="w-full pb-5" v-if="history.length">
                <div
                  class="flex dark:text-gray-50 text-2xl font-serif text-gray-600 w-full uppercase tracking-widest"
                >
                  History
                </div>
                <div class="md:flex">
                  <div class="flex-col w-full" style="flex: 0 0 150%">
                    <div
                      class="flex mt-5 px-7"
                      v-for="h in history"
                      :key="h.id"
                    >
                      <div class="flex-col w-full text-left font-bold">
                        <span class="">{{ h.unitTitle }}</span>
                      </div>
                      <div class="flex-col text-left w-full">
                        {{ h.levelTitle }}
                      </div>
                      <div class="flex-col w-full">
                        <ul class="flex justify-center">
                          <li v-for="i in qtyStars" :key="i">
                            <i
                              v-if="i <= h.stars"
                              class="fas fa-star fa-sm text-yellow-500 mr-1"
                            ></i>
                            <i
                              v-else
                              class="far fa-star fa-sm text-yellow-500 mr-1"
                            ></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="flex-col w-full"></div>
                </div>
              </div>
              <!-- History end -->
            </div>
            <div class="flex-col w-full">
              <div class="personal pl-12 w-full">
                <div class="w-full dark:text-gray-50 md:w-full px-3 mb-6">
                  <h2 class="flex text-2xl text-gray-600 pb-8">
                    Notifications
                  </h2>
                  <label
                    class="flex dark:text-gray-50 tracking-wide font-serif text-gray-900 text-base font-bold mb-2"
                    >Email notifications</label
                  >
                  <div class="flex w-full mb-12">
                    <label
                      for="toggleA"
                      class="flex items-center cursor-pointer"
                    >
                      <div class="mr-8 dark:text-gray-50 text-base text-gray-700 font-medium">
                        Send product update emails
                      </div>
                      <!-- toggle -->
                      <div class="relative">
                        <!-- input -->
                        <input
                          type="checkbox"
                          id="toggleA"
                          class="sr-only"
                          v-model="receiveEmails"
                        />
                        <!-- line -->
                        <div
                          class="block bg-gray-200 w-14 h-8 rounded-full"
                        ></div>
                        <!-- dot -->
                        <div
                          class="dot absolute left-1 top-1 bg-mywhite w-6 h-6 rounded-full transition"
                        ></div>
                      </div>
                      <!-- label -->
                    </label>
                  </div>
                </div>
                <div class="w-full md:w-full px-3 mb-6">
                  <label
                    class="flex dark:text-gray-50 tracking-wide font-serif text-base text-gray-900 text-base font-bold mb-2"
                    >App notifications</label
                  >
                  <div class="flex w-full mb-12">
                    <label
                      for="toggleB"
                      class="flex items-center cursor-pointer"
                    >
                      <div
                        class="mr-8 dark:text-gray-50 text-base pr-4 text-gray-700 font-medium"
                      >
                        Receive push notifications
                      </div>
                      <!-- toggle -->
                      <div class="relative">
                        <!-- input -->
                        <input
                          type="checkbox"
                          id="toggleB"
                          class="sr-only"
                          v-model="receivePushNotif"
                        />
                        <!-- line -->
                        <div
                          class="block bg-gray-200 w-14 h-8 rounded-full"
                        ></div>
                        <!-- dot -->
                        <div
                          class="dot absolute left-1 top-1 bg-mywhite w-6 h-6 rounded-full transition"
                        ></div>
                      </div>
                      <!-- label -->
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-col w-full">
              <h2 class="flex text-2xl dark:text-gray-50 text-gray-600 pb-8">Additional info</h2>
              <div class="personal w-full text-left">
                <table class="min-w-full rounded">
                  <tbody class="divide-y text-base divide-mygray">
                    <tr>
                      <td class="p-4 font-serif">Last sign in</td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-if="lastSignInDate"
                      >
                        {{ convertDate(lastSignInDate) }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Last sign in platform</td>
                      <td
                        class="p-4 font-bold font-serif"
                        v-if="lastSignInPlatform"
                      >
                        {{ lastSignInPlatform }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Sign up platform</td>
                      <td
                        class="p-4 font-bold font-serif"
                        v-if="signupPlatform"
                      >
                        {{ signupPlatform }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Provider</td>
                      <td class="p-4 font-bold font-serif" v-if="provider">
                        {{ provider }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">App version</td>
                      <td class="p-4 font-bold font-serif" v-if="appVersion">
                        {{ appVersion }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr v-if="onboardingAnswers">
                      <td class="p-4 font-serif">Onboarding answers</td>
                      <td
                        class="item font-bold font-serif truncate flex"
                        v-for="answer in onboardingAnswers"
                        :key="answer"
                      >
                        {{ answer }}
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Country</td>
                      <td class="p-4 font-bold font-serif" v-if="country">
                        {{ country }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Timezone</td>
                      <td class="p-4 font-bold font-serif" v-if="timezone">
                        {{ timezone }}
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <td class="p-4 font-serif">Feedback card</td>
                      <td class="p-4 font-bold font-serif" v-if="arrFedback">
                        <ul v-for="(item, index) of arrFedback" :key="item.id">
                          <li>
                            {{ index + 1 }})<br />{{ item.text }}<br /><br />
                          </li>
                        </ul>
                      </td>
                      <td
                        class="p-4 font-bold font-serif text-cool-gray-900"
                        v-else
                      >
                        -
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { db } from "../firebase/config";
import firebase from "firebase/compat";
import { DateTime } from "luxon";
import { createToast } from "mosha-vue-toastify";
export default {
  setup() {},
  data() {
    return {
      uInfo: [],
      uName: "",
      uEmail: "",
      uId: "",
      id: "",
      dishes: [],
      createdAt: null,
      receiveEmails: false,
      isAdmin: false,
      receivePushNotif: false,
      lastSignInDate: null,
      lastSignInPlatform: "",
      provider: "",
      hasChanged: false,
      appVersion: "",
      onboardingAnswers: [],
      country: null,
      arrFedback: [],
      timezone: null,
      feedbackCardSkiped: null,
      qtyStars: 3,
      history: [],
    };
  },
  async created() {
    this.uId = this.$route.params.id;
    const dataUser = await db
      .collection("Users")
      .doc(this.uId)
      .get()
      .then((d) => ({ id: d.id, ...d.data() }));
    this.id = dataUser.id;
    this.uName = dataUser.name;
    this.uEmail = dataUser.email;
    this.receiveEmails = dataUser.receiveEmails;
    this.receivePushNotif = dataUser.receivePushNotif || false;
    this.isAdmin = dataUser.isAdmin || false;
    this.createdAt = dataUser.createdAt;
    this.lastSignInDate = dataUser.lastSignInDate;
    this.lastSignInPlatform = dataUser.lastSignInPlatform;
    this.signupPlatform = dataUser.signupPlatform;
    this.provider = dataUser.provider;
    this.appVersion = dataUser.appVersion;
    this.onboardingAnswers = dataUser.onboardingAnswers;
    this.country = dataUser.country;
    this.timezone = dataUser.timezone;
    this.feedbackCardSkiped = dataUser.feedbackCardSkiped;

    db.collection("Feedbacks")
      .where("userId", "==", dataUser.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.data()) {
            this.arrFedback.push(doc.data());
          }
        });
      });

    //Тут ми знаєм unitId
    // await db
    // .collection(`Users/${this.id}/History`)
    // .get()
    // .then( (d) => {
    //   if(d.docs.length) {
    //     d.docs.forEach(doc => {
    //       doc.data() && this.history.push({ ...doc.data(), levelContentId: doc.id })
    //     });

    //     this.history.forEach((item) => {
    //       db.collection("LevelContent")
    //         .where('levelId', '==', item.levelContentId)
    //         .get()
    //         .then(lc => {
    //           db.collection("Units").doc(lc.docs[0].data().unitId)
    //             .get()
    //             .then(w => {
    //               item.levelTitle = w.data().levels.find(x=>x.id == item.levelContentId ).title;
    //               item.unitTitle = w.data().title;
    //             })
    //         })
    //     })
    //   }
    // });

    //тут шукаєм у всіх Units
    this.dishes = await db
      .collection("LevelContent")
      .get()
      .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));

    const searchedHistorylName = (id) => {
      let docs = this.dishes.find((product) => product.id === id);
      if (docs != undefined) {
        return docs.title;
      }
    };

    console.log(searchedHistorylName);
    await db
      .collection(`Users/${this.id}/History`)
      .get()
      .then((d) => {
        d.docs.forEach((doc) => {
          doc.data() && this.history.push({ ...doc.data(), id: doc.id });
        });

        this.history.forEach((item) => {
          db.collection("Units")
            .get()
            .then((w) => {
              w.docs.forEach(() => {
                let lev = this.dishes.find((x) => x.id == item.id);

                if (lev) {
                  item.levelTitle = lev.title;
                  item.unitTitle = lev.title;
                }
              });
            });
        });
      });
  },

  methods: {
    convertDate(e) {
      return DateTime.fromMillis(e).toFormat("yyyy LLL dd");
    },
    onSubmit(evt) {
      const objData = {
        name: this.uName,
        isAdmin: this.isAdmin,
        receiveEmails: this.receiveEmails,
        receivePushNotif: this.receivePushNotif,
      };
      evt.preventDefault();

      const updateRef = firebase.firestore().collection("Users").doc(this.uId);

      updateRef
        .update(objData)
        .then(() => {
          createToast("User Updated", {
            toastBackgroundColor: "#51A045",
            position: "top-center",
            transition: "zoom",
            timeout: 2224,
            hideProgressBar: "true",
          });
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
<style>
.item:after {
  content: " ";
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-d {
  width: 315px;
}
button:disabled {
  background: transparent;
  color: green;
}
/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #f0f0f0;
}
input:checked ~ .block {
  background-color: #0e0e0e;
}
</style>
