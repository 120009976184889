<template>
  <div class="aboutMedia">
    <section
      class="antialiased scrollbar-hide dark:bg-bggraym dark:text-gray-50 bg-gray-100 text-gray-600 min-h-screen h-auto px-4"
    >
      <div
        class="flex flex-col grow justify-center h-auto pt-16 max-w-7xl mx-auto"
      >
        <div class="flex justify-between list-group-item p-3 mb-1">
          <div class="edit ml-3 block">
            <h2
              class="font-sans dark:text-gray-50 text-gray-800 flex text-4xl pb-3"
            >
              Media Library
            </h2>
          </div>
          <div class="edit ml-3 block w-96">
            <input
              type="search"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              v-model="searchQuery"
              placeholder="Search tag.."
            />
          </div>
          <div class="edit ml-3 block">
            <button @click="openDialogAddImage" class="fas fa-pencil-alt">
              Add Item
            </button>
          </div>
        </div>
        <div
          class="bg-mywhite dark:bg-bggrayl dark:text-gray-50 scrollbar-hide lg:m-auto px-10 py-8 rounded-xl lg:w-full shadow-md max-w-7xl"
        >
          <ul
            role="list"
            class="grid grid-cols-2 gap-x-4 gap-y-8 scrollbar-hide sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-5 xl:gap-x-8"
          >
            <li
              v-for="item of searchedProducts"
              :key="item"
              @click="openDialogUpdateImage(item)"
              class="relative"
            >
              <div
                class="group block scrollbar-hide overflow-hidden w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
              >
                <img
                  :src="item.iconLink"
                  v-if="item.type === 'image'"
                  class="object-cover w-full h-44 rounded-lg pointer-events-none group-hover:opacity-75"
                />
                <video
                  v-if="item.type === 'video'"
                  controls
                  ref="videoPlayer"
                  class="video-js object-cover w-full h-44 rounded-lg pointer-events-none group-hover:opacity-75"
                >
                  <source :src="item.iconLink" />
                </video>
                <!--  <VuePlayerVideo
                  v-if="item.type === 'video'"
                  :src="item.iconLink"
                  :colors="['#ce8314']"
                  :autoplay="false"
                  class="w-47 h-44 pt-7"
                /> -->
              </div>
              <p
                class="mt-2 block dark:text-gray-50 text-sm font-medium text-gray-900 truncate pointer-events-none"
              >
                #{{ item.tags.join(" #") }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <my-dialog v-model:show="showDialogImage">
      <image-form
        style="z-index: 999"
        :image="ImageAddForm"
        :type="type"
        @create="createImage"
        @update="updateImage"
      >
      </image-form>
    </my-dialog>
  </div>
</template>
<script>
import { ref, computed, reactive, toRefs } from "vue";
import MyDialog from "@/components/MyDialog";
import ImageForm from "./components/add-item";
import { db } from "@/firebase/config";
import useStorageLibrary from "@/composables/useStorageLibrary";
import getCollection from "@/composables/getCollectionUnits";
/* import VuePlayerVideo from "vue3-player-video"; */
import "cropperjs/dist/cropper.css";
import "video.js/dist/video-js.css";

/* import VideoPlayer from './components/videoPlayer.vue'; */
export default {
  components: {
    MyDialog,
    ImageForm,
  },

  setup() {
    const { url, uploadImage } = useStorageLibrary();
    const { documents: imgdoc } = getCollection("MediaLibrary");
    const showDialogImage = ref(false);
    const searchQuery = ref("");
    let type = ref("");
    const ImageAddForm = ref({
      tags: [],
      iconLink: null,
      title: null,
    });
    const state = reactive({
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
      },
    });
    const videoPlayer = ref(null);
    const hideDialog = () => {
      showDialogImage.value = false;
      ImageAddForm.value = {
        type: "image",
        tags: [],
        iconLink: null,
        title: null,
      };
    };

    const searchedProducts = computed(() => {
      let images = imgdoc.value;
      if (images) {
        images.sort((a, b) => b.createdAt - a.createdAt);
        let filter = images.filter((image) => {
          if (image.tags)
            return image.tags.find(
              (a) =>
                a.toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1
            );
        });

        return filter;
      }
      return null;
    });

    const openDialogAddImage = async () => {
      showDialogImage.value = true;
      type.value = "Add";
    };
    const openDialogUpdateImage = (i) => {
      showDialogImage.value = true;
      type.value = "Update";
      ImageAddForm.value = i;
    };
    const createImage = async (newImage, file) => {
      newImage.createdAt = Date.now();
      db.collection("MediaLibrary")
        .add(newImage)
        .then(async (a) => {
          await uploadImage(file, "media-library/" + a.id);

          await db
            .collection("MediaLibrary")
            .doc(a.id)
            .update({
              iconLink: url.value,
            })
            .catch((e) => console.log(e));

          hideDialog();
        })
        .catch((e) => console.log(e));
    };
    const updateImage = async (newImage, file, docId) => {
      if (file === null) {
        db.collection("MediaLibrary").doc(docId).update(newImage);
        hideDialog();
      } else {
        db.collection("MediaLibrary")
          .doc(docId)
          .update(newImage)
          .then(async (a) => {
            console.log(a);
            await uploadImage(file, "media-library/" + docId);

            await db
              .collection("MediaLibrary")
              .doc(docId)
              .update({ iconLink: url.value })
              .catch((e) => console.log(e));

            hideDialog();
          })
          .catch((e) => console.log(e));
      }
    };

    return {
      ...toRefs(state),
      showDialogImage,
      openDialogAddImage,
      imgdoc,
      videoPlayer,
      searchedProducts,
      searchQuery,
      openDialogUpdateImage,
      updateImage,
      ImageAddForm,
      createImage,
      hideDialog,
      type,
    };
  },
};
</script>
<style lang="scss">
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.video-js.vjs-playing .vjs-tech {
  pointer-events: none !important;
}
.aboutMedia {
  .vue3-player-video .duration-300 {
    transition-duration: 0.3s;
    z-index: 1 !important;
  }
  .vue3-player-video .w-full {
    width: 100%;
  }
  .vjs_video_1325-dimensions {
    width: 300px;
    height: 200px;
  }
  .vue3-player-video .duration-200 {
    transition-duration: 0.2s;
    display: none;
  }
  .image {
    height: 200px;
    width: 200px;
  }
}
</style>
